import AlertDialog from "../../shared/AlertDialog";
import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import CaseData from "./caseData";
import axios from "axios";
import {
    PMA_DETAILS,
    ADD_PMA_DETAILS,
    INSERT_DECISION_FILE,
    MERGE_MULTIPLE_PDF,
    HTML_TO_PDF,
    GET_DECISION_FILEWITHSAS,   
    GET_DOCUSIGNFILE,
    DELETE_RESPONSE,
    DELETE_PMA_RESPONSE,
    DOCUSIGN_CONNECTOR, isSyncBack,
    env,
    GET_PENDINGCOUNTFORNEUTRAL,
    DELETE_PMA_ADDITIONAL_RESPONSE,
    DELETE_ADDITIONAL_RESPONSE,
    GRAMERLY_CLIENT_ID,
    GRAMERLY_ASSERTION_ID,
    isMask,
    mailTimesheet,
    MASK_PMA_PARTICIPANTS,
    MERGE_DECISIONPMA_RESPONSE,
    EDIT_NEUTRAL_EMAIL,
    voidedDocusign
} from "../../api/baseURL";
import { get } from "../../api/api";
import * as Utility from "../../utils/utility";
import FileUploader from "f1-file-upload-react";
import "./pma.scss";
import NumberFormat from "react-number-format";
import Loading from "../../assets/img/loader.gif";

import PMATemplate from "./pmaTemplate";
import LoadingOverlay from "react-loading-overlay";
import Modal from "react-bootstrap/Modal";
//// Plugins
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { decryptItem, encryptItem } from "../../shared/Encrypt";
document
    .querySelectorAll("grammarly-editor-plugin")
    .forEach((grammarlyEditor) => {
        grammarlyEditor.config = {
            oauthAssertionProvider: async () => {
                //// Call your endpoint to get an assertion
                const response = await fetch(GRAMERLY_ASSERTION_ID, {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        clientId: GRAMERLY_CLIENT_ID,
                    }),
                });
                //// Handle errors
                if (!response.ok) {
                    throw new Error("Error creating assertion");
                }
                //// Return generated assertion
                return await response.json();
            },
        };
    });

export default function PMA(props) {
    const defaultInstance = defaultLayoutPlugin();
    const PMASync = (data) => {
        const hearingDetails = JSON.parse(decryptItem("hearingDetails"));
        hearingDetails.pendingAt = 'pma'
        encryptItem("hearingDetails", JSON.stringify(hearingDetails));
        window.location.href = props.decisionUrl;
        window.location.reload();
    }
    const hearingCode = parseInt(
        props.hearingCode ? props.hearingCode : props.match.params?.hearingCode
    );
    const dateObj = new Date();
    const date = dateObj.getDate();
    const month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ][dateObj.getMonth()];
    const year = dateObj.getFullYear();

    const dateString = `${date}${Utility.nth(date)} day of ${month}, ${year}`;

    useEffect(() => {
        setIsFileUpload(false);
        getPMADetails();
        getTimeSheetDuration();
        //// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    let isDeleteOrBckSpace = false;
    const [pmaDetails, setPMADetails] = useState({});
    //// eslint-disable-next-line no-unused-vars
    const[btnHandle,setBtnHandle]=useState(false);
    const [pmaAmount] = useState({});
    const [pmaAdditionalNotes, setAdditionalNotes] = useState("");
    const [pmaNoLater, setNoLater] = useState("");
    const [isFileUpload, setIsFileUpload] = useState({});
    const [isFormSubmit, setIsFormSubmit] = useState(true);
    const [isPreview, setIsPreview] = useState(false);
    const [totalDuration,setTotalDuration]=useState(0)
    const [IsDocuSigned, setIsDocuSigned] = useState(false);
    const [response, setresponse] = useState(null);
    const [isPreviewResponse, setIsPreviewResponse] = useState(false);
    const [PreviewResponse, setPreviewResponse] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const [isValid, setValid] = useState(false);
    const [partyLength, setPartyLength] = useState(0);
    const [switchUploads, setSwitchUploads] = useState(false);
    const [initialAdditionalFiles, setInitialAdditionalFiles] = useState(null);
    const [caseUnsettled, setcaseUnsettled] = useState(false);
    const [markAsPmaFile, setMarkAsPmaFile] = useState(null);
    const [markAsPmaUploadFile, setMarkAsPmaUploadFile] = useState(null);
    const [decisionFileAvailable, setdecisionFileAvailable] = useState(false);
    const [markAsPmaDecisioFile, setMarkAsPmaDecisioFile] = useState(null);
    const [isNewFormSubmission, setisNewFormSubmission] = useState(true);
    const [isadditionaNotesError, setisadditionaNotesError] = useState(false);
    const [isdisplayAlertError, setdisplayAlertError] = useState(false);
    const[plainTiffDefentError,setPlainTiffDefentError]=useState({});
    const ref = React.createRef()
    const [HearingOfficerEmailPMA, setHearingOfficerEmail] = useState(
        props.caseDetails["hearingOfficerEmail"]
    );
    const [submitRefresh, setSubmitRefresh] = useState(0);
    const [isFileSendForDocusign, setisFileSendForDocusign] = useState(false);
    const [isShowDeleteMessage, setisShowDeleteMessage] = useState(true);
    const [lastDeleteMessage, setlastDeleteMessage] = useState('');
    const [isFormdisabled, setisFormdisabled] = useState(false);
    const [totalSum, setTotalSum] = useState("");
    const [voidenvelopeId, setEnvelopeId] = useState("");
    const TimesheetNotSubmitted =
        props.caseDetails.isTimeSheetSubmitted !== null &&
            props.caseDetails.isTimeSheetSubmitted !== undefined
            ? !props.caseDetails.isTimeSheetSubmitted && !Utility.IsCaseDecision(props.caseDetails["caseStatus"])
            : true;
    const getTotalTime = (timesheets) => {
        const totalspentminutes = calculateTotalTime(timesheets);
        return Utility.toHoursAndMinutes(totalspentminutes);
    };
    const calculateTotalTimeSheet = (timesheets) => {
        if (timesheets !== undefined && timesheets !== null) {
            const totalpostMedTimehours = timesheets.postMedTimehours;
            const totalrevDocTimehours = timesheets.revDocTimehours;
            const totalpostMedTimeminutes = timesheets.postMedTimeminutes;
            const totalrevDocTimeminutes = timesheets.revDocTimeminutes;
            const totalhearingDuration = timesheets.hearingDuration;
            let totalspentminutes =
                totalpostMedTimeminutes +
                totalrevDocTimeminutes +
                totalhearingDuration +
                totalpostMedTimehours * 60 +
                totalrevDocTimehours * 60;

            const arbitrationTime = totalhearingDuration + totalrevDocTimeminutes + (totalrevDocTimehours * 60)
            totalspentminutes = arbitrationTime < timesheets.hearingScheduledDuration * 60 ? timesheets.hearingScheduledDuration * 60 : arbitrationTime


            return totalspentminutes;
        }
        return 0;
    };
   const  getTimeSheetDuration  = ()=>{
        let timesheetDuration = 0;
        const url = `${PMA_DETAILS}?hearingCode=${hearingCode}&caseCode=${props.caseDetails["caseCode"]}`;
        //setIsPreview(false);
        axios
            .get(url, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            })
            .then((res) => {
             let sum = 0;
        if (res?.data?.timeSheets?.length > 0) {
            
            for (let tCal = 0; tCal < res?.data?.timeSheets?.length; tCal++) {
                let localVal = calculateTotalTimeSheet(res?.data?.timeSheets[tCal]);
                
                sum = sum + localVal;
            }
            
            timesheetDuration = (Utility.toHoursAndMinutes(sum));
            setTotalDuration(timesheetDuration);
           //this.setState({"totalDuration":timesheetDuration});
        }
    });
    //console.log("sum",sum);
       // return timesheetDuration;
    }
    const updatedisplayAlert = (e) => {
        setdisplayAlertError(false);
        setIsLoaded(true);
    };
    const handleAmtPayError = (event,index)=>{
       const {value} = event;
            if((value).trim() === ""){
                const tempError = {...plainTiffDefentError};
                tempError[index]="Please fill required field";
                setPlainTiffDefentError(tempError);
            }else{
                removeErrorMessage(index);
            }
           
    }
    const calculateTotalTime = (timesheets) => {
        if (timesheets !== undefined && timesheets !== null) {
            const totalpostMedTimehours = timesheets.postMedTimehours;
            const totalrevDocTimehours = timesheets.revDocTimehours;
            const totalpostMedTimeminutes = timesheets.postMedTimeminutes;
            const totalrevDocTimeminutes = timesheets.revDocTimeminutes;
            const totalhearingDuration = timesheets.hearingDuration;
            const totalspentminutes =
                totalpostMedTimeminutes +
                totalrevDocTimeminutes +
                (totalhearingDuration < timesheets.hearingScheduledDuration * 60 ? timesheets.hearingScheduledDuration * 60 : totalhearingDuration) +
                totalpostMedTimehours * 60 +
                totalrevDocTimehours * 60;

            return totalspentminutes;
        }
        return 0;
    };
    const getPMADetails = () => {
        const url = `${PMA_DETAILS}?hearingCode=${hearingCode}&caseCode=${props.caseDetails["caseCode"]}`;
        setIsPreview(false);

        axios
            .get(url, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            })
            .then((res) => {
                ////getting status is 204 when it has pma data is empty 
                if (res.status === 200 || res.status === 204) {
                    setIsLoaded(true);
                    const tempData = res.data;
                    setEnvelopeId(res?.data?.envelopeId);
                   const tempCaseUnsettled = res?.data?.caseUnsettled;
                    setcaseUnsettled(res?.data?.caseUnsettled)
                    if (res?.data?.additionalDecisionDocuments?.length > 0) {
                        setisFileSendForDocusign(true);

                        let isDecisonFileAvailable = res.data.additionalDecisionDocuments.filter(
                            (itemFilter) => itemFilter.isDecisionFile === true
                        );
                        if (isDecisonFileAvailable.length === 0) {
                            setisNewFormSubmission(true);
                        }
                        else {
                            setisNewFormSubmission(false);
                        }

                        let getPmaFile = res.data.additionalDecisionDocuments.filter(
                            (itemFilter) =>
                                res?.data?.decisionFileName &&
                                itemFilter.fileName === res?.data?.decisionFileName
                        );
                        let listOfFiles = res.data.additionalDecisionDocuments.filter(
                            (itemFilter) =>
                                itemFilter.fileName !== res?.data?.decisionFileName
                        );
                        listOfFiles = listOfFiles.filter(
                            (a, i) =>
                                listOfFiles.findIndex(
                                    (s) => a.fileName && s.fileName && a.fileName === s.fileName
                                ) === i
                        );

                        if (getPmaFile?.length === 1) {
                            listOfFiles.unshift(getPmaFile[0]);
                        }
                        setAdditionalFile(listOfFiles); //// list of additinalfiledata
                    } else {
                        setAdditionalFile(res.data.additionalDecisionDocuments); //// list of additinalfiledata
                    }

                    var decisionfileCheck = res.data.additionalDecisionDocuments.filter(x => { return x.isDecisionFile !== null && x.isDecisionFile });

                    if (decisionfileCheck !== null && decisionfileCheck !== undefined && decisionfileCheck.length > 0) {
                        setMarkAsPmaFile(
                            decisionfileCheck[0].fileName
                        );
                        setMarkAsPmaDecisioFile(
                            decisionfileCheck[0].fileName
                        );
                        setdecisionFileAvailable(Utility.IsCaseClosed(props.caseDetails["caseStatus"]) || decisionfileCheck[0].fileName ? true : false);
                    }
                    else {
                        setMarkAsPmaFile(
                            res.data.decisionFileName ? res.data.decisionFileName : null
                        );
                        setMarkAsPmaDecisioFile(
                            res.data.decisionFileName ? res.data.decisionFileName : null
                        );
                        let isDecisonFileAvailable = res.data.additionalDecisionDocuments.filter(
                            (itemFilter) => itemFilter.isDecisionFile === true
                        );                        
                        //     setdecisionFileAvailable(Utility.IsCaseClosed(props.caseDetails["caseStatus"]) || res.data.decisionFileName ? true : false);                   
                       
                        setdecisionFileAvailable(Utility.IsCaseClosed(props.caseDetails["caseStatus"]) || isDecisonFileAvailable.length>0 ? true : false);
                    }
                    if (res?.data?.timeSheets?.length > 0) {
                        let sum = 0;
                        for (let tCal = 0; tCal < res?.data?.timeSheets?.length; tCal++) {
                            let localVal = calculateTotalTime(res?.data?.timeSheets[tCal]);
                            sum = sum + localVal;
                        }
                        props.timeDurationOnDecision(Utility.toHoursAndMinutes(sum));
                    }

                    if ((res.data.status === "sent" || res.data.isDocuSigned === 1) && res.data.fileName !== "" && res.data.fileName !== null) {
                        const url1 = `${GET_DOCUSIGNFILE}?casereference=${props.caseDetails["caseReference"]}&filename=${res.data.fileName}`;

                        axios
                            .get(url1, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                                },
                            })
                            .then((res1) => {
                               // if (res.data.status === "Submitted") {


                                    const byteString = atob(res1.data); // Add your base64-encoded byte string here

                                    // Convert byte string to Uint8Array
                                    const byteArray = new Uint8Array(byteString.length);
                                    for (let i = 0; i < byteString.length; i++) {
                                        byteArray[i] = byteString.charCodeAt(i);
                                    }

                                    // Convert Uint8Array to Blob
                                    const blob = new Blob([byteArray], { type: 'application/pdf' });
                                    // Create a URL for the Blob
                                    const url = URL.createObjectURL(blob);
                                    setFilebase64(url);
                                    setresponse(url);
                                    setIsDocuSigned(res.data.isDocuSigned === 1 && Utility.IsCaseDecision(props.caseDetails["caseStatus"]));

                                  
                                    setInitialAdditionalFiles(null); //// list of additinalfiledata
                                // } else {
                                //     setFilebase64(null);
                                // }
                            });
                    }
                    let isDecisonFileAvailablecheck = res.data.additionalDecisionDocuments.filter(
                        (itemFilter) => itemFilter.isDecisionFile === true
                    );
                    setisFormdisabled(Utility.IsCaseDecision(props.caseDetails["caseStatus"]) && (isDecisonFileAvailablecheck.length===0));// === null && res?.data?.decisionFileName === undefined || res?.data?.decisionFileName === ''));

                    pmaDetails["amount"] =
                        pmaDetails["amount"] === undefined || pmaDetails["amount"] === null
                            ? 0
                            : pmaDetails["amount"];
                    const isValidTemp = validate(caseUnsettled, tempData);
                    setValid(isValidTemp);
                    setIsFormSubmit(isValidTemp || decisionFileAvailable);

                    if (res.data.status === "Submitted") {
                        setIsSubmitted(true);
                    } else {
                        if (res.data.status === "sent") {
                            const isValidTemp1 = validate(caseUnsettled, tempData);
                            setValid(isValidTemp1);
                            setIsFormSubmit(isValidTemp1 || decisionFileAvailable);
                        } else {

                            setIsFormSubmit(checkFormSubmit(tempData));
                        }
                    }

if(res.data.notes==="" || res.data.notes===undefined || res.data.notes===null)
{
    res.data.notes="pursuant to the CPLR";
}
                    if (!res.data?.participants || res.data?.participants?.length === 0) {
                        tempData.participants = [];
                        tempData.participants.push({
                            defendantName: "",
                            plaintiffName: "",
                            amount: 0,
                        });
                    }
                    const parties = res.data.parties;
                    if (parties !== null && parties !== undefined) {
                        setPartyLength(parties.length);
                    }
                   
                    tempData.parties.map((item) => {
                        item.isExcluded = tempCaseUnsettled === true ? false : true;
                        item.type = item.type === null ? "Additional Signatory" : item.type;
                      if(item.name==="" && item.email==="")
                      {
                        tempData.parties.splice(item.id,1);
                      }
                    });
                    if (
                        tempData &&
                        tempData.timeSheets !== undefined &&
                        tempData.timeSheets !== null &&
                        tempData.timeSheets.length > 0
                    ) {
                        tempData.timeSheets = tempData.timeSheets
                            .slice()
                            .sort((a, b) => (b.hearingDate < a.hearingDate ? 1 : -1));
                    }
                    tempData.updatedDate = new Date();
                   
                    setPMADetails(tempData);
                    // // tempData.parties.map((item) => {
                    // //     if(item.name ==="")
                    // //     {checkValidation(item.id,tempData)}                    
                    // // });
                    const tempTotalSum = tempData.participants
                        .map((x) => x.amount)
                        .reduce((a, b) => parseFloat(a ? a : 0.00) + parseFloat(b ? b : 0.00), 0.00);
                    setTotalSum(parseFloat(tempTotalSum).toFixed(2));
                }
            });
    };
    const checkFormSubmit = (details) => {
        let val = true;
        if (details.participants &&
            details.participants.length > 0 &&
            details.partiesName &&
            details.partiesName.trim().length > 0) {
            if (
                details.participants !== undefined &&
                details.participants !== null
            ) {
                details.participants.forEach((y) => {
                    if (
                        y.plaintiffName.trim().length === 0 ||
                        y.defendantName.trim().length === 0
                    ) {
                        val = false;
                    }
                });
            }

            if (details.parties !== undefined && details.parties !== null) {
                details.parties.forEach((y) => {
                    if (y.email.trim().length === 0 || y.name.trim().length === 0) {
                        val = false;
                    }
                });
            }
        }
        return val;
    }

    const additionalNoteshandler = (event) => {
        setAdditionalNotes(event.target.value);
        setisadditionaNotesError(false);
        setPMADetails({ ...pmaDetails, additionalNotes: event.target.value });
    };
    const noLaterHandler = (event) => {
        setNoLater((event.target.value).trim());
        if((event.target.value).trim() === ""){
            const tempError = {...plainTiffDefentError};
            tempError["noLater"]="Please fill required field";
            setPlainTiffDefentError(tempError);
        }else{
            removeErrorMessage("noLater");
        }
        setPMADetails({ ...pmaDetails, notes: event.target.value });
    };
    const [newErrors, setNewErrors] = useState([]);
    const handleOnChange = (event, index) => {
        const { parties } = pmaDetails;
        const currParty = parties[index];
        let tempNewErrors = newErrors;
        const mail= event.target.value===undefined || event.target.value===null||event.target.value.length==0?"":event.target.value.indexOf(",")!==-1? 
        event.target.value.split(",")[0] : 
        (event.target.value.indexOf(";")!==-1 ? 
        event.target.value.split(";")[0] :event.target.value);
         if ((caseUnsettled && currParty.isExcluded) || (!caseUnsettled)) {
            if (event.target.value.trim()?.length === 0) {
                if (
                    (tempNewErrors?.filter(
                        (m) => m.rowIndex === index && m.erroremail === "Email is required."
                    )).length === 0
                ) {
                    tempNewErrors.push({ erroremail: "Email is required.", rowIndex: index });
                }
                if (
                    tempNewErrors.findIndex(
                        (m14) => m14.rowIndex === index && m14.erroremail === "Invalid Email."
                    ) !== -1
                )
                    tempNewErrors.splice(
                        tempNewErrors.findIndex(
                            (m13) => m13.rowIndex === index && m13.erroremail === "Invalid Email."
                        ),
                        1
                    );

                ////setNewErrors(tempNewErrors);
            } else if (!Utility.isValidEmail(mail)) {
                //// email is not valid
                if (
                    tempNewErrors.findIndex(
                        (m11) => m11.rowIndex === index && m11.erroremail === "Email is required."
                    ) !== -1
                ) {
                    //// remove "Email is required." by index
                    tempNewErrors.splice(
                        tempNewErrors.findIndex(
                            (m1) => m1.rowIndex === index && m1.erroremail === "Email is required."
                        ),
                        1
                    );
                    if (
                        (tempNewErrors?.filter(
                            (m2) => m2.rowIndex === index && m2.erroremail === "Invalid Email."
                        )).length === 0
                    ) {
                        tempNewErrors.push({ erroremail: "Invalid Email.", rowIndex: index });
                    }
                } else if (
                    tempNewErrors.findIndex(
                        (m12) => m12.rowIndex === index && m12.erroremail === "Invalid Email."
                    ) === -1
                ) {
                    //// email is not valid

                    tempNewErrors.push({ erroremail: "Invalid Email.", rowIndex: index });
                } else {
                    console.log("else");
                }
                //// setNewErrors(tempNewErrors);
            } else {
                if (
                    tempNewErrors.findIndex(
                        (m4) => m4.rowIndex === index && m4.erroremail === "Email is required."
                    ) !== -1
                )
                    tempNewErrors.splice(
                        tempNewErrors.findIndex(
                            (m5) => m5.rowIndex === index && m5.erroremail === "Email is required."
                        ),
                        1
                    );

                if (
                    tempNewErrors.findIndex(
                        (m6) => m6.rowIndex === index && m6.erroremail === "Invalid Email."
                    ) !== -1
                )
                    tempNewErrors.splice(
                        tempNewErrors.findIndex(
                            (m7) => m7.rowIndex === index && m7.erroremail === "Invalid Email."
                        ),
                        1
                    );
                //// setNewErrors(tempNewErrors);
            }
        }
         else {
            ////added codition if error is there on mouseover above textbox error is gone...
             if (!(tempNewErrors.length > 0 && tempNewErrors[0].rowIndex!=index)) {             
              // var a =tempNewErrors?.filter((ix) => ix.rowIndex != index)[0]?.rowIndex;
                 tempNewErrors = [];
             }
         }
        setNewErrors(tempNewErrors);
        currParty.email = event.target.value;
        parties[index] = currParty;
        setPMADetails({ ...pmaDetails, parties: parties });
        
    };
    const handleOnNameChange = (event, index) => {
        const { parties } = pmaDetails;
        const currParty = parties[index];
        let tempNewErrorsOnNameChange = newErrors;
        //console.log("value",(event.target.value.trim()).length);
        if ((caseUnsettled && currParty.isExcluded) || (!caseUnsettled)) {
            if ((event.target.value.trim()).length > 100) {

                if (
                    (tempNewErrorsOnNameChange?.filter(
                        (m20) => m20.rowIndex === index && m20.errorname === "Max length should be 100."
                    )).length === 0
                ) {
                    tempNewErrorsOnNameChange.push({
                        errorname: "Max length should be 100.",
                        rowIndex: index,
                    });
                    ////setNewErrors(tempNewErrorsOnNameChange);
                }
                //return;
            } else {
                if (
                    tempNewErrorsOnNameChange.findIndex(
                        (m21) => m21.rowIndex === index && m21.errorname === "Max length should be 100."
                    ) !== -1
                ) {
                    tempNewErrorsOnNameChange.splice(
                        tempNewErrorsOnNameChange.findIndex(
                            (m21) => m21.rowIndex === index && m21.errorname === "Max length should be 100."
                        ),
                        1
                    );
                    ////setNewErrors(tempNewErrorsOnNameChange);
                }
            }


            if (event.target.value.trim()) {
                if (
                    tempNewErrorsOnNameChange.findIndex(
                        (m9) => m9.rowIndex === index && m9.errorname === "Name is required."
                    ) !== -1
                ) {
                    tempNewErrorsOnNameChange.splice(
                        tempNewErrorsOnNameChange.findIndex(
                            (m10) => m10.rowIndex === index && m10.errorname === "Name is required."
                        ),
                        1
                    );
                }

            }
            else {
                if (event.target.value.trim() === "") {//// value is empty add "Name is required."
                    if (
                        tempNewErrorsOnNameChange.findIndex(
                            (m11) => m11.rowIndex === index && m11.errorname === "Invalid Name."
                        ) !== -1
                    ) {
                        //// remove "Email is required." by index
                        tempNewErrorsOnNameChange.splice(
                            tempNewErrorsOnNameChange.findIndex(
                                (m1) => m1.rowIndex === index && m1.errorname === "Invalid Name."
                            ),
                            1
                        );
                    }
                    if (
                        (tempNewErrorsOnNameChange?.filter(
                            (m8) => m8.rowIndex === index && m8.errorname === "Name is required."
                        )).length === 0
                    ) {
                        tempNewErrorsOnNameChange.push({
                            errorname: "Name is required.",
                            rowIndex: index,
                        });
                        ////setNewErrors(tempNewErrorsOnNameChange);
                    }
                }
            }
        }

        else { 
            if (!(tempNewErrorsOnNameChange.length > 0 && tempNewErrorsOnNameChange[0].rowIndex!=index)) {         
            tempNewErrorsOnNameChange = [];
            }
         }

        setNewErrors(tempNewErrorsOnNameChange);
        if (isDeleteOrBckSpace) {
            isDeleteOrBckSpace = false;
            currParty.name = event.target.value;
            parties[index] = currParty;
            setPMADetails({ ...pmaDetails, parties: parties });
        }
        {
            currParty.name = event.target.value;
            parties[index] = currParty;
            setPMADetails({ ...pmaDetails, parties: parties });
        }


    };
const checkValidation=(index,data)=>{
   
        const { parties } = data;
        const currParty = parties[index];
        let tempNewErrors = newErrors;
        //const mail= event.target.value===undefined || event.target.value===null||event.target.value.length==0?"":event.target.value.indexOf(",")!==-1? 
        //event.target.value.split(",")[0] : 
       // (event.target.value.indexOf(";")!==-1 ? 
       // event.target.value.split(";")[0] :event.target.value);
    if ((caseUnsettled && currParty.isExcluded) || (!caseUnsettled)) {
        if (currParty.email.trim() === "") {
            if (
                (tempNewErrors?.filter(
                    (m) => m.rowIndex === index && m.erroremail === "Email is required."
                )).length === 0
            ) {
                tempNewErrors.push({ erroremail: "Email is required.", rowIndex: index });
            }
            if (
                tempNewErrors.findIndex(
                    (m14) => m14.rowIndex === index && m14.erroremail === "Invalid Email."
                ) !== -1
            )
                tempNewErrors.splice(
                    tempNewErrors.findIndex(
                        (m13) => m13.rowIndex === index && m13.erroremail === "Invalid Email."
                    ),
                    1
                );

            //// setNewErrors(tempNewErrors);
        } else if (!Utility.isValidEmail(currParty.email)) {
            //// email is not valid
            if (
                tempNewErrors.findIndex(
                    (m11) => m11.rowIndex === index && m11.erroremail === "Email is required."
                ) !== -1
            ) {
                //// remove "Email is required." by index
                tempNewErrors.splice(
                    tempNewErrors.findIndex(
                        (m1) => m1.rowIndex === index && m1.erroremail === "Email is required."
                    ),
                    1
                );
                if (
                    (tempNewErrors?.filter(
                        (m2) => m2.rowIndex === index && m2.erroremail === "Invalid Email."
                    )).length === 0
                ) {
                    tempNewErrors.push({ erroremail: "Invalid Email.", rowIndex: index });
                }
            } else if (
                tempNewErrors.findIndex(
                    (m12) => m12.rowIndex === index && m12.erroremail === "Invalid Email."
                ) === -1
            ) {
                //// email is not valid

                tempNewErrors.push({ erroremail: "Invalid Email.", rowIndex: index });
            } else {
                console.log("else");
            }
            //// setNewErrors(tempNewErrors);
        } else {
            if (
                tempNewErrors.findIndex(
                    (m4) => m4.rowIndex === index && m4.erroremail === "Email is required."
                ) !== -1
            )
                tempNewErrors.splice(
                    tempNewErrors.findIndex(
                        (m5) => m5.rowIndex === index && m5.erroremail === "Email is required."
                    ),
                    1
                );

            if (
                tempNewErrors.findIndex(
                    (m6) => m6.rowIndex === index && m6.erroremail === "Invalid Email."
                ) !== -1
            )
                tempNewErrors.splice(
                    tempNewErrors.findIndex(
                        (m7) => m7.rowIndex === index && m7.erroremail === "Invalid Email."
                    ),
                    1
                );
            ////setNewErrors(tempNewErrors);
        }
    }     
    else {
        tempNewErrors = [];
    }
    setNewErrors(tempNewErrors);
        currParty.email = currParty.email;
        parties[index] = currParty;
        setPMADetails({ ...pmaDetails, parties: parties });
        
   
    
       
        let tempNewErrorsOnNameChange = newErrors;
        //console.log("value",(event.target.value.trim()).length);
     if ((caseUnsettled && currParty.isExcluded) || (!caseUnsettled)) {
        if ((currParty.name.trim()).length > 100) {

            if (
                (tempNewErrorsOnNameChange?.filter(
                    (m20) => m20.rowIndex === index && m20.errorname === "Max length should be 100."
                )).length === 0
            ) {
                tempNewErrorsOnNameChange.push({
                    errorname: "Max length should be 100.",
                    rowIndex: index,
                });
                setNewErrors(tempNewErrorsOnNameChange);
            }
            //return;
        } else {
            if (
                tempNewErrorsOnNameChange.findIndex(
                    (m21) => m21.rowIndex === index && m21.errorname === "Max length should be 100."
                ) !== -1
            ) {
                tempNewErrorsOnNameChange.splice(
                    tempNewErrorsOnNameChange.findIndex(
                        (m21) => m21.rowIndex === index && m21.errorname === "Max length should be 100."
                    ),
                    1
                );
                setNewErrors(tempNewErrorsOnNameChange);
            }
        }

        if (currParty.name.trim()) {
            if (
                tempNewErrorsOnNameChange.findIndex(
                    (m9) => m9.rowIndex === index && m9.errorname === "Name is required."
                ) !== -1
            ) {
                tempNewErrorsOnNameChange.splice(
                    tempNewErrorsOnNameChange.findIndex(
                        (m10) => m10.rowIndex === index && m10.errorname === "Name is required."
                    ),
                    1
                );
            }

        }
        else {
            if (currParty.name.trim() === "") {//// value is empty add "Name is required."
                if (
                    tempNewErrorsOnNameChange.findIndex(
                        (m11) => m11.rowIndex === index && m11.errorname === "Invalid Name."
                    ) !== -1
                ) {
                    //// remove "Email is required." by index
                    tempNewErrorsOnNameChange.splice(
                        tempNewErrorsOnNameChange.findIndex(
                            (m1) => m1.rowIndex === index && m1.errorname === "Invalid Name."
                        ),
                        1
                    );
                }
                if (
                    (tempNewErrorsOnNameChange?.filter(
                        (m8) => m8.rowIndex === index && m8.errorname === "Name is required."
                    )).length === 0
                ) {
                    tempNewErrorsOnNameChange.push({
                        errorname: "Name is required.",
                        rowIndex: index,
                    });
                    setNewErrors(tempNewErrorsOnNameChange);
                }
            }
        }
    } else {
        tempNewErrorsOnNameChange = [];
    }
        setNewErrors(tempNewErrorsOnNameChange);
        if (isDeleteOrBckSpace) {
            isDeleteOrBckSpace = false;
            currParty.name = currParty.name;
            parties[index] = currParty;
            setPMADetails({ ...pmaDetails, parties: parties });
        }
        {
            currParty.name =currParty.name;
            parties[index] = currParty;
            setPMADetails({ ...pmaDetails, parties: parties });
        }


    
};
    const onkeydown = (event) => {
        if (event.keyCode === 8 || event.keyCode === 46) {
            isDeleteOrBckSpace = true;
        }
    }

    const onPartiesHereToChange = (event) => {
        const key = event.target.name;
        const value = event.target.value;
        setPMADetails({ ...pmaDetails, [key]: value });
        {
            if (isDeleteOrBckSpace) {
                isDeleteOrBckSpace = false;
                setPMADetails({ ...pmaDetails, [key]: value });
            }
        }
        setPMADetails({ ...pmaDetails, [key]: value });
    };

    const excludeClickHandler = (event, index) => {
        const parties = [...pmaDetails.parties];
        const currParty = parties[parseInt(event.target.name)];
        currParty.isExcluded = !currParty.isExcluded;
        parties[index] = currParty;
        setPMADetails({ ...pmaDetails, parties: parties });
        if(caseUnsettled)
        {
            checkValidation(index,pmaDetails);
        }
    };

    //// file upload

    const [file, setFile] = useState(null);
    const [fileCount, setFileCount] = useState(0);
    const [additionalFile, setAdditionalFile] = useState(null);
    const [filebase64, setFilebase64] = useState(null);
    const [deletedResponse, setdeletedResponse] = useState(false);
    const [deletedResponseMsg, setdeletedResponseMsg] = useState("");
    const [replaceFiles, setReplaceFiles] = useState([]);

    //// file upload
    const setFileDropperValue = async () => {
        console.log('focused')
        let fileInput = document.getElementById("file_uploader")
        setTimeout(() => {
            fileInput.value = ""
        }, 1000)
    }
    const onDropHandler = (files, event) => {
        setFile(files);
        setFileCount(files?.length);
        if (files !== null && files !== undefined && files.length > 0) {
            let valueArr = files.map(function (item2) {
                return item2.name;
                // return fileNameCheckforDots(item2.name);
            });
            // let valueArrFindDuplicates = files.map(function (item2) {
            //     return item2.name.substring(0,item2.name.lastIndexOf("."));
            //     // return fileNameCheckforDots(item2.name);
            // });
            let DuvalueArrplicate = valueArr.some(function (item1, idx) {
                return valueArr.indexOf(item1) 
                 !== idx;
            });
            if (DuvalueArrplicate) {
                setisUploadErrorMessage(true);
                setUploadErrorMessage(
                    "File(s) in red already exists and will be overwritten on upload"
                );

                //// get duplicate file names

                // let duplicatelist1 = valueArr.filter((e, i, a) => a.indexOf(e) !== i);
                let duplicatelist1 = valueArr.filter((e, i, a) => a.indexOf(e) !== i);
                let tempReplaceFiles = replaceFiles;
                for (var x = 0; x < duplicatelist1.length; x++) {
                    tempReplaceFiles.push(duplicatelist1[x]);
                }
                setReplaceFiles(Array.from(new Set(tempReplaceFiles)));
                //// end get duplicate file names
                return false;
            }
        }

        if (
            additionalFile !== null &&
            additionalFile !== undefined &&
            additionalFile.length > 0
        ) {
            const resadditionalFile = additionalFile.filter((n) => {
                return files.some((n2) => {
                    return n.fileName === getDocxAsPDF(n2.name);
                });
            });
            if (
                resadditionalFile !== null &&
                resadditionalFile !== undefined &&
                resadditionalFile.length > 0
            ) {
                setisUploadErrorMessage(true);
                setUploadErrorMessage(
                    "File(s) in red already exists and will be overwritten on upload"
                );
                let tempReplaceFiles = replaceFiles;
                for (var item = 0; item < resadditionalFile.length; item++) {
                    tempReplaceFiles.push(resadditionalFile[item].fileName);
                }
                setReplaceFiles(Array.from(new Set(tempReplaceFiles)));

                return false;
            }
        }

        {
            let temp = initialAdditionalFiles ? initialAdditionalFiles : [];
            temp.push(...files);
            if (files !== null && files !== undefined && files.length > 0) {
                setIsFileUpload(true);
            } else {
                setIsFileUpload(false);
            }
        }
        setFileDropperValue()
    };

    useEffect(() => {
        const fileValid = document.getElementsByClassName("file-validation");
        if (fileValid.length > 0) {
           
            setIsFileUpload(false);
            setValid(false);
        }
    }, [isFileUpload, isValid]);

    //// create reference
    const childRef = useRef();
    const onFileRemove = (e, fileRemove) => {
        console.log("fileRemove");
    };
    const removeFile = (e, fileRemove, index) => {
        childRef.current.removeFile(e, fileRemove, index);
        if (fileRemove.name === markAsPmaFile || fileRemove.name === markAsPmaUploadFile
            || fileRemove.name === markAsPmaDecisioFile) {
            setMarkAsPmaFile(null);
            setMarkAsPmaUploadFile(null)
            setMarkAsPmaDecisioFile(null)
        }
        let hasDuplicate = true;
        if (
            additionalFile !== null &&
            additionalFile !== undefined &&
            additionalFile.length > 0
        ) {
            const resadditionalFile = additionalFile.filter((n) => {
                return childRef.current.state.uploadedFiles.some((n2) => {
                    return n.fileName === n2.name;
                });
            });
            if (
                resadditionalFile !== null &&
                resadditionalFile !== undefined &&
                resadditionalFile.length > 0
            ) {
                hasDuplicate = false;
                setisUploadErrorMessage(true);
                setUploadErrorMessage(
                    "File(s) in red already exists and will be overwritten on upload"
                );

                let allFiles = file.map(function (item) {
                    return item.name;
                });
                if (
                    allFiles.filter((x) => x === fileRemove.name).length === 1 &&
                    resadditionalFile.filter((x) => x.fileName === fileRemove.name)
                        .length === 0
                ) {
                    let removeFileFromReplaceFiles = replaceFiles;
                    setReplaceFiles(
                        removeFileFromReplaceFiles.filter(function (item) {
                            return item !== fileRemove.name;
                        })
                    );
                }
                if (replaceFiles.indexOf(fileRemove.name) === -1) {
                    //// document why this block is empty
                    console.log("empty doc")
                }
            } else {
                checkduplicateFiles(file, fileRemove);
            }
        }
        setFileCount(file?.length);

        hasDuplicate && checkduplicateFiles(file, fileRemove);

        setFilebase64(null);
        setIsFileUpload(false);
        setValid(false);
        if (file === [] || file === null || file.length === 0) {
            setIsFileUpload(false);
            setValid(validate(caseUnsettled));
        } else {
            setIsFileUpload(true);
            setValid(validate(caseUnsettled));
        }

        setIsFormSubmit(true);
    };

    const checkduplicateFiles = (fileList, fileRemove) => {
        let valueArr = fileList.map(function (item) {
            return item.name;
        });
        let DuvalueArrplicate = valueArr.some(function (item, idx) {
            return valueArr.indexOf(item) !== idx;
        });

        if (DuvalueArrplicate) {
            if (valueArr.filter((x) => x === fileRemove.name).length === 1) {
                let removeFileFromReplaceFiles = replaceFiles;
                setReplaceFiles(
                    removeFileFromReplaceFiles.filter(function (item) {
                        return item !== fileRemove.name;
                    })
                );
            }
            setisUploadErrorMessage(true);
            setUploadErrorMessage(
                "File(s) in red already exists and will be overwritten on upload"
            );
        } else {
            setisUploadErrorMessage(false);
            setUploadErrorMessage("");
            setReplaceFiles([]);
        }
    };
    const getDocxAsPDF=(docxFileName)=>{
        if(docxFileName === null){
            return null;
        }
        let fileName=docxFileName;
        let fileIndex=docxFileName.lastIndexOf('.')
        let extension=docxFileName.substring(docxFileName.lastIndexOf('.'))
        if(extension===".docx" || extension===".doc")
        {
            fileName = docxFileName.substring(0,fileIndex)+"_docx.pdf";
        }
        return fileName;
    }
    const markPmaFileOnChange = (event) => {
        setMarkAsPmaUploadFile(event.target.value);
        // let fileName = event.target.value.split(".")[0] + ".pdf"
        // if (event.target.value.split(".")[1] !== 'pdf') {
        //     fileName = event.target.value.split(".")[0] + "_" + event.target.value.split(".")[1].substring(0) + ".pdf"
        // }
        setMarkAsPmaFile(event.target.value);
        setMarkAsPmaDecisioFile(event.target.value);
    };
    const renderUploadedFilesItem = (file1, index) => {
        setIsFileUpload(true);
        setValid(true);

        return (
            <>
                {
                    <li
                        className="uploadFiles"
                        style={{
                            borderColor:
                                replaceFiles.length > 0 &&
                                    //replaceFiles.indexOf(file1.name.substring(0,file1.name.lastIndexOf("."))) !== -1
                                    (replaceFiles.indexOf(getDocxAsPDF(file1.name)) !== -1
                                    || replaceFiles.indexOf(file1.name) !== -1)
                                    ? "red"
                                    : "#ccc",

                            border:
                                replaceFiles.length > 0 &&
                                    //replaceFiles.indexOf(file1.name.substring(0,file1.name.lastIndexOf("."))) !== -1
                                    (replaceFiles.indexOf(getDocxAsPDF(file1.name)) !== -1
                                    || replaceFiles.indexOf(file1.name) !== -1)
                                    ? "1px solid red"
                                    : "none",
                        }}
                        key={index}
                    >
                        <div className="row">
                            <div className="col-4">
                                <div className="file-action">
                                    <input
                                        type="radio"
                                        className="file-action-radio"
                                        id={file1.name + "_MarkAsPMA"}
                                        name="MarkAsPMA"
                                        value={file1.name}
                                        checked={file1.name === markAsPmaUploadFile}
                                        onChange={markPmaFileOnChange}
                                    />
                                    <label className="file-label" for={file1.name + "_MarkAsPMA"}>
                                        Mark as PMA
                                    </label>
                                </div>
                            </div>
                            <div className="col-8">
                                <span className="action">
                                    {" "}
                                    <i
                                        style={{
                                            color:
                                                file1.name.indexOf(".pdf") !== -1 ? "#DC1D00" : "blue",
                                        }}
                                        className={
                                            file1.name.indexOf(".pdf") !== -1
                                                ? "fa fa-file-pdf-o"
                                                : "fa fa-file-word-o"
                                        }
                                    ></i>
                                </span>
                               
                                {/* <span className="file-name">{fileNameCheckforDots(file1.name)}</span> */}
                                <span className="file-name">{file1.name}</span>

                                <span
                                    className="action"
                                    onClick={(e) => {
                                        setOpenConfirmDialog(true);
                                        // setDeleteMessage(
                                        //     `Are you sure you want to delete this file: ${fileNameCheckforDots(file1.name)}`
                                        // );
                                        setDeleteMessage(
                                            `Are you sure you want to delete this file: ${file1.name}`
                                        );
                                        setEventFileRemove(e);
                                        setFileFileRemove(file1);
                                        setlastDeleteMessage('removeFile');
                                        setFileRemoveIndex(index);
                                    }}
                                >
                                    <i className="fa fa-trash"></i>
                                </span>
                            </div>
                        </div>
                    </li>
                }
            </>
        );
    };

    useEffect(() => {
        if (fileCount) {
            const totalFIle =
                file.length +
                (additionalFile !== null && additionalFile.length !== undefined
                    ? additionalFile.length
                    : 0);
            if (totalFIle > 15) {
                setisUploadErrorMessage1(true);
                setUploadErrorMessage1("Maximum 15 files can be uploaded");
                setIsFileUpload(false);

            } else {
                setisUploadErrorMessage1(false);
                setUploadErrorMessage1("");
                setIsFileUpload(true);

            }
        }
    }, [fileCount]);
    const fileNameCheckforDots=(fileName)=>
    {
      let tempFileChange=fileName.split(".");
                  if (fileName.split(".").length>2 && tempFileChange[tempFileChange.length-1] == 'pdf') {
                      return fileName.split(".")[0]  + "_" + fileName.split(".")[1].substring(0) + ".pdf";
                  }
                  return fileName;
              }
    const docSubmitHandler = () => {
        const totalFIle =
            file.length +
            (additionalFile !== null && additionalFile.length !== undefined
                ? additionalFile.length
                : 0);
        if (totalFIle > 15) {
            setisUploadErrorMessage1(true);
            setUploadErrorMessage1("Maximum 15 files can be uploaded");
            setIsFileUpload(false);

            return false;
        }
        setisUploadErrorMessage(false);
        setUploadErrorMessage("");
        setIsFileUpload(true);

        setIsLoading(true);
        if(markAsPmaUploadFile !== ""){
            setBtnHandle(true);
        }
        setMarkAsPmaUploadFile('')
        const url = `${MERGE_DECISIONPMA_RESPONSE}`;
        const formData = new FormData();
        const distinctFiles = file.filter(
            (a, i) => file.findIndex((s) => a.name === s.name) === i
        );
        if (file.length > 0) {
            for (var x = 0; x < distinctFiles.length; x++) {
                //// the name has to be 'files' so that .NET could properly bind it
                formData.append("files", distinctFiles[x]);
            }
        }

        if (additionalFile !== null && additionalFile.length > 0) {
            let tempAdditionalFiles = additionalFile.filter(
                (o) => !distinctFiles.some((v) => v.name === o.fileName)
            );
            if (tempAdditionalFiles.length === 0) {
                formData.append("additionalFile", []);
            }

            for (
                var indexadditionalFile = 0;
                indexadditionalFile < tempAdditionalFiles.length;
                indexadditionalFile++
            ) {
                //// the name has to be 'files' so that .NET could properly bind it
                // let tempFileChange=tempAdditionalFiles[indexadditionalFile].fileName.split(".");
                // if (tempAdditionalFiles[indexadditionalFile].fileName.split(".").length>2 && tempFileChange[tempFileChange.length-1] == 'pdf') {
                //     //tempAdditionalFiles[indexadditionalFile].fileName = tempAdditionalFiles[indexadditionalFile].fileName.split(".")[0] + "_" + tempAdditionalFiles[indexadditionalFile].fileName.split(".")[1].substring(0) + ".pdf";
                //     tempAdditionalFiles[indexadditionalFile].fileName = tempAdditionalFiles[indexadditionalFile].fileName;
                // }
                tempAdditionalFiles[indexadditionalFile].fileName = tempAdditionalFiles[indexadditionalFile].fileName;

                   formData.append(
                    `additionalFile[${indexadditionalFile}].fileName`,
                    tempAdditionalFiles[indexadditionalFile].fileName
                );
                formData.append(
                    `additionalFile[${indexadditionalFile}].fileLink`,
                    tempAdditionalFiles[indexadditionalFile].fileLink
                );
                formData.append(
                    `additionalFile[${indexadditionalFile}].fileName`,
                    tempAdditionalFiles[indexadditionalFile].fileName
                );
                //// formData.append(`additionalFile`,additionalFile[x].fileLink);
            }
        } else {
            formData.append("additionalFile", []);
        }
        formData.append("caseCode", props.caseDetails["caseCode"]);
        formData.append("decision", markAsPmaFile === undefined || markAsPmaFile === null ? null : markAsPmaFile);
        formData.append("caseReference", props.caseDetails["caseReference"]);
        const config = {
            headers: {
                "content-type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        axios.post(url, formData, config).then((response1) => {
            let additionalFileChanges = additionalFile ? additionalFile : [];
            let docxAsPMAFile=getDocxAsPDF(markAsPmaFile);
            if (
                response1.status === 200 &&
                response1.data !== null &&
                response1.data.length > 0
            ) {
                let length = response1.data.length;
                for (
                    var additionalFileChangesIndex = 0;
                    additionalFileChangesIndex < length;
                    additionalFileChangesIndex++
                ) {
                    const fileIndex = additionalFileChanges.findIndex((n) => n.fileName === response1.data[additionalFileChangesIndex].fileName);
                    if (fileIndex === -1) {

                        additionalFileChanges.push({
                            //fileName: fileNameCheckforDots(response1.data[additionalFileChangesIndex].fileName),
                            fileName: response1.data[additionalFileChangesIndex].fileName,
                            fileLink: response1.data[additionalFileChangesIndex].fileLink,
                            isOnlineDecisionFile:
                            docxAsPMAFile ===
                              //  fileNameCheckforDots(   response1.data[additionalFileChangesIndex].fileName)
                                response1.data[additionalFileChangesIndex].fileName
                                    ? true
                                    : false,
                                    isDecisionFile: docxAsPMAFile === response1.data[additionalFileChangesIndex].fileName ? true : false,
                            
                                    //isDecisionFile: markAsPmaFile === fileNameCheckforDots(response1.data[additionalFileChangesIndex].fileName) ? true : false,
                            updateDate: response1.data[additionalFileChangesIndex].updateDate
                        });
                    }
                    else {
                        additionalFileChanges[fileIndex].fileLink = response1.data[additionalFileChangesIndex].fileLink;
                        additionalFileChanges[fileIndex].isOnlineDecisionFile = docxAsPMAFile ===
                        //fileNameCheckforDots(  response1.data[additionalFileChangesIndex].fileName)

                        response1.data[additionalFileChangesIndex].fileName
                            ? true
                            : false;
                      
                            //additionalFileChanges[fileIndex].isDecisionFile = additionalFileChanges[fileIndex].isOnlineDecisionFile = markAsPmaFile === fileNameCheckforDots(response1.data[additionalFileChangesIndex].fileName) ? true : false;
                            additionalFileChanges[fileIndex].isDecisionFile = additionalFileChanges[fileIndex].isOnlineDecisionFile = markAsPmaFile === response1.data[additionalFileChangesIndex].fileName ? true : false;
                        additionalFileChanges[fileIndex].updateDate = response1.data[additionalFileChangesIndex].updateDate

                    }
                }

                //// remove duplicate and  decision / pma file from additional files
                let getPmaFile = additionalFileChanges.filter(
                    (itemFilter) => docxAsPMAFile && itemFilter.fileName === docxAsPMAFile
                );
                additionalFileChanges = additionalFileChanges.filter(
                    (itemFilter) => itemFilter.fileName !== docxAsPMAFile
                );
                additionalFileChanges = additionalFileChanges.filter(
                    (a, i) =>
                        additionalFileChanges.findIndex(
                            (s) => a.fileName && s.fileName && a.fileName === s.fileName
                        ) === i
                );

                if (getPmaFile?.length === 1) {
                    additionalFileChanges.unshift(getPmaFile[0]);
                }

                setAdditionalFile(additionalFileChanges);
                setPMADetails({ ...pmaDetails, updatedDate: new Date() });
                setdecisionFileAvailable(Utility.IsCaseClosed(props.caseDetails["caseStatus"]) ||
                    markAsPmaFile !== null &&
                    markAsPmaFile !== "" &&
                    markAsPmaFile !== undefined
                );
                setFile(null);
            }
            const url1 = `${ADD_PMA_DETAILS}`;
            const fileNameString =
                file !== null ? `${props.caseDetails["caseReference"]}.pdf` : "";
            const decisionFileNameString = docxAsPMAFile;
            setMarkAsPmaFile(docxAsPMAFile)
            const body = {
                parties: [],
                amount: 0,
                notes: "",
                additionalNotes: pmaDetails.additionalNotes,
                caseCode: parseInt(props.caseDetails["caseCode"]),
                status: "Submitted",
                id: pmaDetails.id,
                fileName: fileNameString,
                decisionFileName: decisionFileNameString,
                decision: pmaDetails.decision,
                issueName: pmaDetails.issueName,
                participants: [],
                userCode: `${decryptItem("usercode")}`,
                envelopeId: "",
                emailList: [],
                hearingCode: `${props.caseDetails["hearingCode"]}`,
                partiesName: pmaDetails.partiesName,
                caseUnsettled: caseUnsettled,
                additionalDecisionDocuments: additionalFileChanges,
                meetingAttendies: "",
                envelopeHistories: pmaDetails.envelopeHistories
            };

            axios
                .post(url1, body, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                })
                .then((res) => {
                    if (res.status === 200) {
                        setIsSubmitted(true);
                        props.isPMAorDecisionSubmited();
                        setIsFileUpload(false);
                        setIsFormSubmit(true);
                        setIsLoading(false);
                        childRef.current.resetUploadedFiles();
                        setInitialAdditionalFiles(null); //// set additional file list after upload
                        setSwitchUploads(true);
                        setIsFileUpload(false);
                        setValid(validate(caseUnsettled));
                        setisFileSendForDocusign(true);
                        window.scrollTo(0, 0);
                    }
                })
                .catch((error) => {
                    setdisplayAlertError(true);
                    setIsLoading(false);
                });
        });
    };

    const submitHandler = (event) => {

        event.preventDefault();
        var el = document.getElementsByClassName("pagebreakafter")[0];
        el.innerHTML = '<div style="page-break-after:always;"></div>';
        
        if (
            caseUnsettled &&
            (pmaDetails.additionalNotes === null ||
                pmaDetails.additionalNotes === undefined ||
                pmaDetails.additionalNotes === "")
        ) {
            setAdditionalNotes("Case is unresolved");
            pmaDetails.additionalNotes = "Case is unresolved";
        }
        setisadditionaNotesError(false);
        let htmltopdf = "";
        //// 1st Scenario If case is marked as Unsettled or unresolved.
        if (caseUnsettled) {
            htmltopdf = document.getElementById("pmaTemplate");
            if (htmltopdf) {
                htmltopdf = htmltopdf.innerHTML;
                openPreview(htmltopdf, caseUnsettled, false);
            }
        } else {
            //// 2nd Scenario if uploaded decision file is available.
            if (decisionFileAvailable) {
                htmltopdf = document.getElementById(
                    "timesheetdetailsDecision"
                );
                if (htmltopdf) {
                    htmltopdf = htmltopdf.innerHTML;
                    if(htmltopdf==='')
                    {
                     htmltopdf='<div></div>'
                    }
                    openPreview(htmltopdf, false, true);
                }
            } else {
                htmltopdf = document.getElementById("pmaTemplate");
                if (htmltopdf) {
                    htmltopdf = htmltopdf.innerHTML;
                    openPreview(htmltopdf, false, false);
                }
            }
        }
    };
    const removeErrorMessage = (index,both=false)=>{
        const tempError = {...plainTiffDefentError};
        if(tempError.hasOwnProperty(index)){
            delete tempError[index];
          
        }
        if(both === true){
           
            if(tempError.hasOwnProperty("d"+index)){
                delete tempError["d"+index];
            }
            if(tempError.hasOwnProperty("m"+index)){
                delete tempError["m"+index];
            }
            let newTempError = {}
            if(Object.keys(tempError).length > 0){
            Object.keys(tempError).forEach(element=>{
                let current_index = (parseInt(element.replace(/\D/g,'')));
                console.log(current_index +"<"+ index);
                if(current_index > index){
                    current_index = current_index - 1;
                    let newElement = "";
                    if(element.includes("d")){
                        newElement = "d"+current_index;
                    } else if(element.includes("m")){
                        newElement = "m"+current_index;
                    }else{
                        newElement = current_index;
                    }
                    newTempError[newElement] = newElement;
                }else{
                    newTempError[element] = tempError[element];
                }
            })
        }
            //console.log("newTempError",newTempError)
            setPlainTiffDefentError(newTempError);
            let docIndex = document.getElementById("focusInput");
             if(docIndex){ docIndex.focus();  setTimeout(function(){docIndex.click(); },500)}
             
             setSubmitRefresh((new Date().getTime()));
        }else{
            setPlainTiffDefentError(tempError);
        }
        
       
       
        //ref.current &&  ref.current.focus();
    }
    const dataURLtoFile = (dataurl, filename) => {
        try {
            const arr = dataurl.split(","),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        } catch (ex) { }
    };
    const MergeDecision = (fileLink, fileName) => {
        const url_merge = `${MERGE_MULTIPLE_PDF}`;
        const formData = new FormData();
        const distinctFiles =
            file !== null && file !== undefined && file.length > 0
                ? file.filter((a, i) => file.findIndex((s) => a.name === s.name) === i)
                : [];
        if (file !== null && file !== undefined && file.length > 0) {
            for (var x = 0; x < distinctFiles.length; x++) {
                //// the name has to be 'files' so that .NET could properly bind it
                formData.append("files", distinctFiles[x]);
            }
        } else {
            formData.append("files", null);
        }

        const fileIndex = additionalFile.findIndex((n) => n.fileName === fileName);
        if (fileIndex === -1) {
            additionalFile.push({
                fileName: fileName,
                fileLink: fileLink,
                isOnlineDecisionFile: true,
                isDecisionFile: true,
                updateDate: new Date()
            });
        } else {
            additionalFile[fileIndex].fileLink = fileLink;
            additionalFile[fileIndex].isOnlineDecisionFile = true;
            additionalFile[fileIndex].isDecisionFile = true;
            additionalFile[fileIndex].updateDate = new Date();
        }
        if (additionalFile !== null && additionalFile.length > 0) {
            let tempAdditionalFiles = additionalFile.filter(
                (o) => !distinctFiles.some((v) => v.name === o.fileName)
            );
            for (
                var indexadditionalFile = 0;
                indexadditionalFile < tempAdditionalFiles.length;
                indexadditionalFile++
            ) {
                //// the name has to be 'files' so that .NET could properly bind it
                formData.append(
                    `additionalFile[${indexadditionalFile}].fileName`,
                    tempAdditionalFiles[indexadditionalFile].fileName
                );
                formData.append(
                    `additionalFile[${indexadditionalFile}].fileLink`,
                    tempAdditionalFiles[indexadditionalFile].fileLink
                );

                formData.append(
                    `additionalFile[${indexadditionalFile}].isOnlineDecisionFile`,
                    tempAdditionalFiles[indexadditionalFile].isOnlineDecisionFile !=
                    null &&
                    tempAdditionalFiles[indexadditionalFile].isOnlineDecisionFile !=
                    undefined
                );
                formData.append(
                    `additionalFile[${indexadditionalFile}].isDecisionFile`,
                    tempAdditionalFiles[indexadditionalFile].isDecisionFile !=
                    null &&
                    tempAdditionalFiles[indexadditionalFile].isDecisionFile !=
                    undefined
                );
                formData.append(
                    `additionalFile[${indexadditionalFile}].updateDate`,
                    tempAdditionalFiles[indexadditionalFile].updateDate !=
                    null &&
                    tempAdditionalFiles[indexadditionalFile].updateDate !=
                    undefined
                );
            }
        } else {
            formData.append("additionalFile", []);
        }

        formData.append("caseCode", props.caseDetails["caseCode"]);
        formData.append("decision", fileName);
        formData.append("timesheetDetailsFile", "");
        formData.append("caseReference", props.caseDetails["caseReference"]);
        const config = {
            headers: {
                "content-type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        axios
            .post(url_merge, formData, config)
            .then((response1) => {
                if (
                    response1.status === 200 &&
                    response1.data !== null &&
                    response1.data.length > 0
                ) {
                    const link = response1.data[0].fileLink;
                    const byteString = atob(response1.data[0].fileLink); // Add your base64-encoded byte string here

                    // Convert byte string to Uint8Array
                    const byteArray = new Uint8Array(byteString.length);
                    for (let i = 0; i < byteString.length; i++) {
                        byteArray[i] = byteString.charCodeAt(i);
                    }

                    // Convert Uint8Array to Blob
                    const blob = new Blob([byteArray], { type: 'application/pdf' });


                    // Create a URL for the Blob
                    const url = URL.createObjectURL(blob);

                    setFilebase64(url);
                    setresponse(url);
                    setPreviewResponse("");
                    setIsPreview(true);

                    setIsPreviewResponse(false);

                    setMarkAsPmaFile(fileName);
                    setMarkAsPmaDecisioFile(fileName);
                    setAdditionalFile(additionalFile);
                }
            })
            .catch((error) => {
                setdisplayAlertError(true);
                setIsLoaded(false);
            });
    };
    const MergeDecisionTimesheet = (fileLink, fileName, timesheetDetails) => {
        const htmltopdfurl = `${HTML_TO_PDF}`;
        const signature = document.getElementById("signatureDecision").innerHTML;
        const body1 = {
            filename: `${hearingCode}PMAFile`,
            htmlInput: signature,
            casename: `${props.caseDetails["caseName"]}`,
        };
        const fileNameSign = `${props.caseDetails["caseReference"]}_signature.pdf`;
        axios
            .post(htmltopdfurl, body1, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            })
            .then((res) => {
                if (res.status === 200) {
                    const fileObj = dataURLtoFile(
                        `data:application/pdf;base64,${res.data}`,
                        fileNameSign
                    );
                    const url = INSERT_DECISION_FILE;
                    const formDataIncertDecisionFile = new FormData();
                    formDataIncertDecisionFile.append("file", fileObj);
                    formDataIncertDecisionFile.append("caseReference", props.caseDetails["caseReference"]);
                    formDataIncertDecisionFile.append("fileName", fileNameSign);
                    const config = {
                        headers: {
                            "content-type": "multipart/form-data",
                            "Access-Control-Allow-Origin": "*",
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    };
                    axios
                        .post(url, formDataIncertDecisionFile, config)
                        .then((response2) => {
                            if (response2.status === 200) {
                                const url1 = `${GET_DECISION_FILEWITHSAS}?caseReference=${props.caseDetails["caseReference"]}&filename=${fileNameSign}`;
                                axios
                                    .get(url1, {
                                        headers: {
                                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                                        },
                                    })
                                    .then((res1) => {
                                        //// Call Preview
                                        if (res1.status === 200) {
                                            const url_merge = `${MERGE_MULTIPLE_PDF}`;
                                            const formData = new FormData();
                                            const distinctFiles =
                                                file !== null && file !== undefined && file.length > 0
                                                    ? file.filter(
                                                        (a, i) =>
                                                            file.findIndex((s) => a.name === s.name) === i
                                                    )
                                                    : [];
                                            if (
                                                file !== null &&
                                                file !== undefined &&
                                                file.length > 0
                                            ) {
                                                for (var x = 0; x < distinctFiles.length; x++) {
                                                    //// the name has to be 'files' so that .NET could properly bind it
                                                    formData.append("files", distinctFiles[x]);
                                                }
                                            } else {
                                                formData.append("files", null);
                                            }
                                            const fileIndex = additionalFile.findIndex(
                                                (n) => n.fileName === fileName
                                            );
                                            if (fileIndex === -1) {
                                                additionalFile.push({
                                                    fileName: fileName,
                                                    fileLink: fileLink,
                                                    isOnlineDecisionFile: true,
                                                    isDecisionFile: false,
                                                });
                                            } else {
                                                additionalFile[fileIndex].fileLink = fileLink;
                                                additionalFile[fileIndex].isOnlineDecisionFile = true;
                                                additionalFile[fileIndex].isDecisionFile = false;
                                            }
                                            const fileSignIndex = additionalFile.findIndex(
                                                (n) => n.fileName === fileNameSign
                                            );
                                            if (fileSignIndex === -1) {
                                                additionalFile.push({
                                                    fileName: fileNameSign,
                                                    fileLink: res1.data.result,
                                                    isOnlineDecisionFile: true,
                                                    isDecisionFile: false,
                                                });
                                            } else {
                                                additionalFile[fileSignIndex].fileLink = fileLink;
                                                additionalFile[
                                                    fileSignIndex
                                                ].isOnlineDecisionFile = true;
                                                additionalFile[
                                                    fileSignIndex
                                                ].isDecisionFile = false;
                                            }

                                            if (
                                                additionalFile !== null &&
                                                additionalFile.length > 0
                                            ) {
                                                let tempAdditionalFiles = additionalFile.filter(
                                                    (o) =>
                                                        !distinctFiles.some((v) => v.name === o.fileName)
                                                );
                                                for (
                                                    var indexadditionalFile = 0;
                                                    indexadditionalFile < tempAdditionalFiles.length;
                                                    indexadditionalFile++
                                                ) {
                                                    //// the name has to be 'files' so that .NET could properly bind it
                                                    formData.append(
                                                        `additionalFile[${indexadditionalFile}].fileName`,
                                                        tempAdditionalFiles[indexadditionalFile].fileName
                                                    );
                                                    formData.append(
                                                        `additionalFile[${indexadditionalFile}].fileLink`,
                                                        tempAdditionalFiles[indexadditionalFile].fileLink
                                                    );
                                                    formData.append(
                                                        `additionalFile[${indexadditionalFile}].isOnlineDecisionFile`,
                                                        tempAdditionalFiles[indexadditionalFile]
                                                            .isOnlineDecisionFile !== null &&
                                                        tempAdditionalFiles[indexadditionalFile]
                                                            .isOnlineDecisionFile !== undefined
                                                    );
                                                    formData.append(
                                                        `additionalFile[${indexadditionalFile}].isDecisionFile`,
                                                        tempAdditionalFiles[indexadditionalFile].isDecisionFile !=
                                                        null &&
                                                        tempAdditionalFiles[indexadditionalFile].isDecisionFile !=
                                                        undefined
                                                    );
                                                }
                                            } else {
                                                formData.append("additionalFile", []);
                                            }

                                            formData.append(
                                                "caseCode",
                                                props.caseDetails["caseCode"]
                                            );
                                            formData.append(
                                                "decision",
                                                timesheetDetails ? markAsPmaFile : fileName
                                            );
                                            formData.append(
                                                "timesheetDetailsFile",
                                                timesheetDetails ? fileName : ""
                                            );
                                            formData.append(
                                                "caseReference",
                                                props.caseDetails["caseReference"]
                                            );
                                            const configMerge = {
                                                headers: {
                                                    "content-type": "multipart/form-data",
                                                    "Access-Control-Allow-Origin": "*",
                                                    Authorization: `Bearer ${localStorage.getItem(
                                                        "token"
                                                    )}`,
                                                },
                                            };
                                            axios
                                                .post(url_merge, formData, configMerge)
                                                .then((response1) => {
                                                    if (
                                                        response1.status === 200 &&
                                                        response1.data !== null &&
                                                        response1.data.length > 0
                                                    ) {
                                                        const link = response1.data[0].fileLink;
                                                        const byteString = atob(response1.data[0].fileLink); // Add your base64-encoded byte string here

                                                        // Convert byte string to Uint8Array
                                                        const byteArray = new Uint8Array(byteString.length);
                                                        for (let i = 0; i < byteString.length; i++) {
                                                            byteArray[i] = byteString.charCodeAt(i);
                                                        }
                                    
                                                        // Convert Uint8Array to Blob
                                                        const blob = new Blob([byteArray], { type: 'application/pdf' });
                                    
                                    
                                                        // Create a URL for the Blob
                                                        const url = URL.createObjectURL(blob);

                                                        setFilebase64(url);
                                                        setresponse(url);
                                                        setPreviewResponse("");
                                                        setIsPreview(true);


                                                        setIsPreviewResponse(false);
                                                        setMarkAsPmaFile(response1.data[0].fileName);
                                                        setMarkAsPmaDecisioFile(response1.data[0].fileName);
                                                        let additionalFileUpdate = additionalFile.filter(
                                                            function (obj) {
                                                                return obj.fileName !== fileNameSign;
                                                            }
                                                        );
                                                        additionalFileUpdate = additionalFileUpdate.filter(
                                                            function (obj) {
                                                                return obj.fileName !== fileName;
                                                            }
                                                        );
                                                        setAdditionalFile(additionalFileUpdate);
                                                    }
                                                })
                                                .catch((error) => {
                                                    setdisplayAlertError(true);
                                                    setIsLoaded(false);
                                                });
                                        }
                                    })
                                    .catch((error) => {
                                        setdisplayAlertError(true);
                                        setIsLoaded(false);
                                    });
                            }
                        })
                        .catch((error) => {
                            setdisplayAlertError(true);
                            setIsLoaded(false);
                        });
                }
            })
            .catch((error) => {
                setdisplayAlertError(true);
                setIsLoaded(false);
            });
    };
    //// Usage example:
    //// Show Preview
    const openPreview = (htmltopdf, caseResolved, timesheetDetails) => {
        setIsLoading(true);

        const htmltopdfurl = `${HTML_TO_PDF}`;

        const body1 = {
            filename: hearingCode + "PMAFile",
            htmlInput: htmltopdf,
            casename: `${props.caseDetails.caseName}`,
        };
        const fileName = `${props.caseDetails["caseReference"]}${timesheetDetails ? "_timesheet" : "_decision"
            }.pdf`;

        axios
            .post(htmltopdfurl, body1, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            })
            .then((res) => {
                if (res.status === 200) {
                    const _filebase64 = dataURLtoFile(
                        "data:application/pdf;base64," + res.data,
                        fileName
                    );
                    const url = INSERT_DECISION_FILE;
                    const formData = new FormData();
                    formData.append("file", _filebase64);
                    formData.append("caseReference", props.caseDetails["caseReference"]);
                    formData.append("fileName", fileName);
                    const config = {
                        headers: {
                            "content-type": "multipart/form-data",
                            "Access-Control-Allow-Origin": "*",
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    };
                    axios
                        .post(url, formData, config)
                        .then((resp1) => {
                            if (res.status === 200) {
                                const url1 = `${GET_DECISION_FILEWITHSAS}?caseReference=${props.caseDetails["caseReference"]}&filename=${fileName}`;

                                axios
                                    .get(url1, {
                                        headers: {
                                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                                        },
                                    })
                                    .then((res1) => {
                                        //// Call Preview
                                        if (res1.status === 200) {

                                            const byteString = atob(res1.data); // Add your base64-encoded byte string here

                                            // Convert byte string to Uint8Array
                                            const byteArray = new Uint8Array(byteString.length);
                                            for (let i = 0; i < byteString.length; i++) {
                                                byteArray[i] = byteString.charCodeAt(i);
                                            }

                                            // Convert Uint8Array to Blob
                                            const blob = new Blob([byteArray], { type: 'application/pdf' });
                                            // Create a URL for the Blob
                                            const url_new = URL.createObjectURL(blob);
                                            if (caseResolved) {
                                                setFilebase64(url_new);
                                                setresponse(url_new);
                                                setIsPreview(true);

                                                setPreviewResponse("");
                                                setIsPreviewResponse(false);

                                            } else if (timesheetDetails) {

                                                MergeDecisionTimesheet(
                                                    url_new,
                                                    fileName,
                                                    timesheetDetails
                                                );

                                            } else {

                                                MergeDecision(url_new, fileName);

                                            }
                                        } else {
                                            setFilebase64(null);
                                            setresponse(null);
                                        }
                                        setIsLoading(false);
                                    });
                            }
                        })
                        .catch((error) => {
                            setdisplayAlertError(true);
                            setIsLoading(false);
                        });
                }
            })
            .catch((error) => {
                setdisplayAlertError(true);
                setIsLoading(false);
            });
    };
    const cancelPreview = () => {
        setIsLoaded(true);
        setIsPreview(false);
        setIsLoading(false);
        var fileName1 = `${props.caseDetails["caseReference"]}` + `_decision.pdf`;
        if ((response || additionalFile.length > 0) && isFileSendForDocusign) {
            setisShowDeleteMessage(true);
            setisFileSendForDocusign(true);
        }
        else {
            setisShowDeleteMessage(false);
            deleteAdditionalResponse(fileName1, false);
            if (additionalFile.length > 0) {
                for (let i = 0; i < additionalFile.length; i++) {
                    deleteAdditionalResponse(additionalFile[i].fileName, false);
                }
            }
            setisFileSendForDocusign(false);
        }
        if (isNewFormSubmission) {
            deleteOnCancelPreview(fileName1, false);
        }
    };
    const sendForDocusign = (event) => {
        event.preventDefault();
        setisNewFormSubmission(false);
        setIsPreview(false);
        setIsLoaded(false);
        const url = `${ADD_PMA_DETAILS}`;

        const email = !MASK_PMA_PARTICIPANTS
            ? pmaDetails.parties === null || pmaDetails.parties === undefined
                ? []
                : JSON.parse(JSON.stringify(pmaDetails.parties))
            : [];
        var hearingOfficerEmail = {
            name: `${props.caseDetails["hearingOfficerName"]}`,
            email: !EDIT_NEUTRAL_EMAIL
                ? `${HearingOfficerEmailPMA}`
                : isMask
                    ? `${mailTimesheet}`
                    : `${props.caseDetails["hearingOfficerEmail"]}`,
            isExcluded: false,
            isHearingOfficer: true,
        };
        const fileIndex = email.findIndex((n) => n.name === hearingOfficerEmail.name && n.email === hearingOfficerEmail.email);
        if (fileIndex < 0) {
            email.push(hearingOfficerEmail);
        }
        email.forEach(function (item, index) {
            item.email=   item.email.indexOf(",")!==-1? 
            item.email.split(",")[0] : 
            (item.email.indexOf(";")!==-1 ? 
            item.email.split(";")[0 ] : item.email)
            item.cCEmail = "";
            item.cCName = "";
            item.role = "";
            item.id = index + "";
        });
        const fileNameString = markAsPmaDecisioFile;

        const body = pmaDetails;

        body.parties = pmaDetails.parties;
        body.isDocuSigned = 0;
        body.amount = pmaDetails.amount !== undefined ? pmaDetails.amount : 0;
        body.notes = pmaNoLater ? pmaNoLater : pmaDetails.notes===''?"pursuant to the CPLR":pmaDetails.notes;
        body.additionalNotes = pmaAdditionalNotes ? pmaAdditionalNotes : pmaDetails.additionalNotes;
        body.caseCode = parseInt(props.caseDetails["caseCode"]);
        body.status = "sent";
        body.id = "";
        body.fileName = fileNameString;
        body.decisionFileName = markAsPmaFile;
        body.decision = pmaDetails.decision;
        body.issueName = pmaDetails.issueName;
        body.participants = pmaDetails.participants;
        body.userCode = `${decryptItem("usercode")}`;
        body.envelopeId = "";
        body.emailList = email;
        body.hearingCode = `${props.caseDetails["hearingCode"]}`;
        body.partiesName = pmaDetails.partiesName;
        body.caseUnsettled = caseUnsettled;
        body.additionalDecisionDocuments = additionalFile;
        body.hearingofficerName = `${props.caseDetails["hearingOfficerName"]}`;
        body.meetingAttendies = "";
        body.envelopeHistories= pmaDetails.envelopeHistories;
        axios
            .post(url, body, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            })
            .then((res) => {
                if (res.status === 200) {
                    props.isPMAorDecisionSubmited();
                    setIsFileUpload(false);
                    const data = body;
                    if (data && data.emailList.length > 0) {
                        data.emailList.map((item) => {
                            item.isExcluded =
                                item.isExcluded === false && item.isHearingOfficer !== true
                                    ? true
                                    : false;
                        });
                    }
                    //data.fileName = response;
                    data.env = env;
                    data.caseMethod = "PMA";
                    data.status = "sent";
                    data.caseReference = props.caseDetails["caseReference"];
                    data.caseName = `${props.caseDetails.caseName}`;
                    axios
                        .post(DOCUSIGN_CONNECTOR, data)
                        .then((response2) => {

                            if (response2.status === 200) {
                                setisFileSendForDocusign(true);
                                if (isSyncBack) {
                                    PMASync(data);
                                }
                                else {
                                    const hearingDetails = JSON.parse(decryptItem("hearingDetails"));
                                    hearingDetails.pendingAt = 'pma'
                                    encryptItem("hearingDetails", JSON.stringify(hearingDetails));
                                    window.location.href = props.decisionUrl;
                                    window.location.reload();
                                }
                            }

                        })
                        .catch((error) => {
                            setdisplayAlertError(true);
                           
                        });
                    setIsLoaded(false);
                    //// for Showing Pending for Esignature
                    setFilebase64(null);
                    setresponse(null);
                    setIsSubmitted(true);
                    setIsFormSubmit(true);
                    if (Utility.IsHearingOfficer()) {
                        
                        get(
                            `${GET_PENDINGCOUNTFORNEUTRAL}?neutralEmail=${decryptItem("neutralsEmail")}&userCode=${decryptItem ("jCode")}`,
                            {
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                                },
                            }
                        ).then((resp2) => {
                            localStorage.setItem("pendingTaskCount", `${resp2.data}`);
                        });
                    }
                }
            })
            .catch((error) => {
                setdisplayAlertError(true);
            });
    };

    const checkParticipants = (paticipantsdata) => {
        let count = 0;
        for (let i = 0; i < paticipantsdata.length; i++) {
            if (paticipantsdata[i].defendantName && paticipantsdata[i].plaintiffName &&
                paticipantsdata[i].amount) {
                count++;
            }
        }
        if (count === paticipantsdata.length) {
            return true;
        }
        return false;
    }

    //// End Show Preview
    //// OnLostFocus Save Data...
    const SaveData = (event,fileNameChange) => {
        if (newErrors.length === 0) {
            setMessage("");
        }
        if(event!=null && event!=undefined){ event.preventDefault();
        }
        setValid(validate(caseUnsettled));
        const flag = validate(caseUnsettled) || decisionFileAvailable;
        setIsFormSubmit(flag);
        const url = `${ADD_PMA_DETAILS}`;
        const email = !MASK_PMA_PARTICIPANTS
            ? pmaDetails.parties === null || pmaDetails.parties === undefined
                ? []
                : pmaDetails.parties
            : [];
        email.forEach(function (item, index) {
            item.cCEmail = "";
            item.cCName = "";
            item.role = "";
            item.id = index + "";
        });
        const fileNameString = markAsPmaDecisioFile;

        const body = {
            parties: pmaDetails.parties,
            amount: pmaAmount[0] !== undefined ? pmaAmount : 0,
            notes: pmaNoLater ? pmaNoLater : pmaDetails.notes===''?"pursuant to the CPLR":pmaDetails.notes,
            additionalNotes: pmaAdditionalNotes ? pmaAdditionalNotes : pmaDetails.additionalNotes,
            caseCode: props.caseDetails["caseCode"],
            status: pmaDetails?.participants?.length > 0 &&
                checkParticipants(pmaDetails?.participants) &&
                pmaDetails.notes &&
                pmaDetails.additionalNotes &&
                pmaDetails.partiesName ? "Submitted" : "",
            id: "",
            fileName: fileNameString,
            decisionFileName: fileNameChange!=null && fileNameChange!=undefined && fileNameChange?'': markAsPmaFile,
            decision: pmaDetails.decision,
            issueName: pmaDetails.issueName,
            participants: pmaDetails.participants.filter(function (obj) {
                if (parseFloat(obj.amount)) {
                    return obj;
                }
            }),
            userCode: `${decryptItem("usercode")}`,
            envelopeId: "",
            emailList: email,
            hearingCode: props.caseDetails["hearingCode"] + "",
            partiesName: pmaDetails.partiesName,
            caseUnsettled: caseUnsettled,
            additionalDecisionDocuments: additionalFile,
            hearingofficerName: `${props.caseDetails["hearingOfficerName"]}`,
            envelopeHistories: pmaDetails.envelopeHistories
                };

        axios
            .post(url, body, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            })
            .then((res) => {
                if (res.status === 200) {
                    const flag1 = validate(caseUnsettled) || decisionFileAvailable;
                    setIsFormSubmit(flag1);

                }
            })
            .catch((error) => {
                setdisplayAlertError(true);
            });
    };

    
    const [amountError, setIsAmountError] = useState(false);
    const [plaintiffError, setIsPaintiffError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
   
    const [uploaderrorMessage, setUploadErrorMessage] = useState("");
    const [uploaderrorMessage1, setUploadErrorMessage1] = useState("");
    const [isuploaderrorMessage1, setisUploadErrorMessage1] = useState(false);
    const [isuploaderrorMessage, setisUploadErrorMessage] = useState(false);
    const [message, setMessage] = useState("");

    const handleAddContainer = () => {
        if (!amountError && !plaintiffError && !caseUnsettled) {
            const obj = {
                defendantName: "",
                plaintiffName: "",
                amount: "0.00",
            };

            if (
                pmaDetails.participants === undefined ||
                pmaDetails.participants === null
            ) {
                pmaDetails.participants = [];
            }

            const list = pmaDetails.participants;
            const tempError = {...plainTiffDefentError};
            tempError[list.length]=list.length;
            tempError["d"+(list.length)]=list.length;
            setPlainTiffDefentError(tempError);
            list.push(obj);
            setPMADetails({ ...pmaDetails, participants: list });
        } else {
            if(!caseUnsettled){
            setMessage("Please resolve errors first");
            }
        }
    };

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [isConfirmedDelete, setIsConfirmedDelete] = useState(false);
    const [deleteIndexDefendantPantiff, setDeleteIndexDefendantPantiff] =
        useState(-1);
    const [deleteIndexSignatories, setDeleteIndexSignatories] = useState(-1);
    const [deleteMessage, setDeleteMessage] = useState("");

    //// dleete from fileupload controller
    const [eventFileRemove, setEventFileRemove] = useState(undefined);
    const [fileFileRemove, setFileFileRemove] = useState(undefined);
    const [fileRemoveIndex, setFileRemoveIndex] = useState(-1);

    //// delete file from Addtional file list
    const [additionalFileRemove, setAdditionalFileRemove] = useState(undefined);

    //// delete case files
    const [caseReference, setCaseReference] = useState(false);

    const [isResubmit,setIsResubmit]= useState(false);
  
    const closeConfirmDialog = (value) => {
       if(value === true){
        if(lastDeleteMessage === "deleteParticipants"){
            if(deleteIndexDefendantPantiff > -1){
                removeErrorMessage(deleteIndexDefendantPantiff,true);
            }
        }else{
            if(deleteIndexSignatories > -1){
                removeSignatoryError(deleteIndexSignatories);
            }
        }

        
       }
      
        //console.log("valuevalue",deleteIndexSignatories)
        setOpenConfirmDialog(false);
        setIsConfirmedDelete(value);
        setDeleteMessage("");
        var _decisionFile=markAsPmaDecisioFile;
     if(isResubmit && value===true){
        setPMADetails({...pmaDetails,status:""});
        setBtnHandle(false);
        const url = `${voidedDocusign}`;
        let file=     additionalFile.findIndex(obj => {
            return obj.fileName === _decisionFile
          });
          const fileList=additionalFile;
          setMarkAsPmaFile(null);
          setMarkAsPmaUploadFile(null);
          setMarkAsPmaDecisioFile(null);
          setdecisionFileAvailable(false);
        
          
       
     deleteOnCancelPreview(_decisionFile, false);
    
    
        if(_decisionFile==null || _decisionFile==''){
            if (Utility.IsCaseDecision(props.caseDetails["caseStatus"])) {
                setdecisionFileAvailable(false);
            }
            
        }
     setIsResubmit(!isResubmit);
      // Call Voided Envelope docusign method        
      axios
      .post(url, JSON.stringify(voidenvelopeId))
      .then((response3) => {
          if (response3.status === 200) {             
                    setEnvelopeId(''); 
                    setdecisionFileAvailable(false);   
                    window.location.reload();       
          }
      })
      .catch((error) => {
          setdisplayAlertError(true);
          setIsLoaded(false);

      });
    }
    };
    const setDeleteIndexDefendantPantiffError = (index,event)=>{
        //console.log("pmaDetails",pmaDetails)
        setlastDeleteMessage('deleteParticipants');
        setDeleteIndexDefendantPantiff(index);
    }

    useEffect(() => {
        if (isConfirmedDelete) {
            

            if (deleteIndexDefendantPantiff !== -1 && lastDeleteMessage=='deleteParticipants') {
                deleteParticipants(deleteIndexDefendantPantiff);
                setDeleteIndexDefendantPantiff(-1);
                setIsConfirmedDelete(false);
                return;
            }
            if (deleteIndexSignatories !== -1 && lastDeleteMessage=='deleteSignatories') {
                deleteSignatories(deleteIndexSignatories);
                setMessage("");
                setDeleteIndexSignatories(-1);
                setIsConfirmedDelete(false);
                return;
            }
            if (fileRemoveIndex !== -1 && lastDeleteMessage=='removeFile') {
                removeFile(eventFileRemove, fileFileRemove, fileRemoveIndex);
                setFileRemoveIndex(-1);
                setEventFileRemove(undefined);
                setFileFileRemove(undefined);
                setIsConfirmedDelete(false);
                return;
            }
            if (additionalFileRemove && lastDeleteMessage=='deleteAdditionalResponse') {
                deleteAdditionalResponse(additionalFileRemove);
                if (additionalFile === markAsPmaFile) {
                    setMarkAsPmaFile(null);
                    setMarkAsPmaUploadFile(null)
                    setMarkAsPmaDecisioFile(null)
                    setdecisionFileAvailable(false);
                }
                setAdditionalFileRemove(undefined);
                setIsConfirmedDelete(false);
                return;
            }
            if (caseReference && lastDeleteMessage=='caseReference') {
                deleteResponse();
                setCaseReference(false);
                setIsConfirmedDelete(false);
                return;
            }
            setIsConfirmedDelete(false);
        }
        setDeleteIndexDefendantPantiff(-1);
        setDeleteIndexSignatories(-1);
    }, [isConfirmedDelete]);

    const deleteParticipants = (index) => {
        const participantsobj = JSON.parse(JSON.stringify([...pmaDetails.participants])) ;
        participantsobj.splice(index, 1);
        setPMADetails({ ...pmaDetails, participants: participantsobj });
        const tempTotalSum = participantsobj
            .map((x) => x.amount)
            .reduce((a, b) => parseFloat(a ? a : 0.00) + parseFloat(b ? b : 0.00), 0.00);
        setTotalSum(parseFloat(tempTotalSum).toFixed(2));
        setIsPaintiffError(false);
        document.getElementById("focusInput").click();
        setDeleteIndexDefendantPantiff(-1);
    };

    const deleteSignatories = (index) => {
        const signatoriesobj =JSON.parse(JSON.stringify([...pmaDetails.parties]));
        signatoriesobj.splice(index, 1);
        setPMADetails({ ...pmaDetails, parties: signatoriesobj });
        let newIndex = -1;
        newErrors && [...newErrors].forEach((elements,subindex)=>{
            if(elements.rowIndex === index){
                newIndex = subindex;
            }
        })
        //console.log("newIndex",newIndex)
        if(newIndex !== -1){
            console.log("newIndex",newIndex);
            let tempErrors =  [...newErrors];
            tempErrors.splice(newIndex,1);
            setNewErrors(tempErrors)
        }
        setDeleteIndexSignatories(-1);
    };

    const handleAddParties = () => {
        if (newErrors.length === 0) {
            const obj = {
                name: "",
                email: "",
                type: "Additional Signatory",
                isExcluded: caseUnsettled ? false : true,
            };

            if (pmaDetails.parties === undefined || pmaDetails.parties === null) {
                pmaDetails.parties = [];
            }

            const list = pmaDetails.parties;
            let tempError = [...newErrors];
            if (!caseUnsettled) {
                tempError.push({
                    errorname: "Name is required.",
                    rowIndex: list.length
                });
                tempError.push({
                    erroremail: "Email is required.",
                    rowIndex: list.length
                });
            }
            else {
                tempError = [];
            }
            setNewErrors(tempError);
            list.push(obj);
            setPMADetails({ ...pmaDetails, parties: list });
        } else {
            if (!caseUnsettled) {
                setMessage("Please resolve party errors first");
            }
        }
    };
    const onPlaintiffNameChange = (event, index) => {
        const participant = pmaDetails.participants;
        const obj = participant[index];
        const value = event.target.value;
        obj.plaintiffName = event.target.value;
        participant[index] = obj;
        if(obj.errorMessage=== "" || obj.errorMessage=== undefined || obj.errorMessage=== null)
        {
            obj.errorMessage= "";
        }
        if (value !== "") {
            setMessage("");
            obj.errorMessage=obj.errorMessage.replace("Plaintiff name required", "").replace(",","");
            obj.hilightPlantiff=false;
            setErrorMessage(errorMessage.replace("Plaintiff name required", "").replace(",",""));
            setIsPaintiffError(false);
            removeErrorMessage(index);

        }
        else {
            if (!obj.errorMessage.includes("Plaintiff name Required")) {
                setPMADetails({ ...pmaDetails, participants: participant });
                if(obj.errorMessage=== "" || obj.errorMessage=== undefined || obj.errorMessage=== null)
                {
                    obj.errorMessage=`${obj.errorMessage.replace(",","")}  Plaintiff name required`;
                     setErrorMessage(`${errorMessage.replace(",","")}  Plaintiff name required`);
                    }
                    else{
                        obj.errorMessage=`${obj.errorMessage.replace(",","")} ${obj.errorMessage.replace(",","").trim()?",":""}  Plaintiff name required`;
                          setErrorMessage(`${errorMessage.replace(",","")} ${errorMessage.replace(",","").trim()?",":""}  Plaintiff name required`);
                    
                        }
                    obj.hilightPlantiff=true;
                setIsPaintiffError(true);
                const tempError = {...plainTiffDefentError};
                tempError[index]=index;
                setPlainTiffDefentError(tempError);
            }

        }        
        {
            if (isDeleteOrBckSpace) {
                isDeleteOrBckSpace = false;
                setPMADetails({ ...pmaDetails, participants: participant });
            }
        }
        setPMADetails({ ...pmaDetails, participants: participant });
    };

    const onDefendantChange = (event, index) => {
        const participant = pmaDetails.participants;
        const obj = participant[index];
        const value = event.target.value;
        obj.defendantName = value;
        participant[index] = obj;
        if(obj.errorMessage=== "" || obj.errorMessage=== undefined || obj.errorMessage=== null)
        {
            obj.errorMessage= "";
        }
        if (value !== "") {
            setMessage("");
            obj.errorMessage=obj.errorMessage.replace("Defendant name required", "").replace(",","")
            setErrorMessage(errorMessage.replace("Defendant name required", "").replace(",",""));
            obj.HilightDefendent=false;
            setIsPaintiffError(false);
            removeErrorMessage("d"+index);
        
        }
        else {
            if (!errorMessage.includes("Defendant name Required")) {
                setPMADetails({ ...pmaDetails, participants: participant });
                if(obj.errorMessage=== "" || obj.errorMessage=== undefined || obj.errorMessage=== null){
                    obj.errorMessage=`${obj.errorMessage.replace(",","")}  Defendant name required`;
                    setErrorMessage(`${errorMessage.replace(",","")}  Defendant name required`);
                  
                } else {
                    obj.errorMessage=`${obj.errorMessage.replace(",","")} ${obj.errorMessage.replace(",","").trim()?",":""}  Defendant name required`;
                        setErrorMessage(`${errorMessage.replace(",","")} ${errorMessage.replace(",","").trim()?",":""}  Defendant name required`);
                       
                    }
                obj.HilightDefendent=true;
                setIsPaintiffError(true);
                const tempError = {...plainTiffDefentError};
                tempError["d"+index]=index;
                setPlainTiffDefentError(tempError);
               
            }
        }
        
        {
            if (isDeleteOrBckSpace) {
                isDeleteOrBckSpace = false;
                setPMADetails({ ...pmaDetails, participants: participant });
            }
        }
        setPMADetails({ ...pmaDetails, participants: participant });
    };

    const onValueChange = (event, index) => {
        const { value } = event;

        const participant = pmaDetails.participants;
        const obj = participant[index];
        const re = /^\s*[1-9]\d*(\.\d)?\s*$/;
        let valueFixed = 0;
        if (value !== '' && value !== null && value !== undefined) {
            valueFixed = parseFloat(value).toFixed(2);
        }
        obj.amount = value === '' || value === null || value === undefined ? value : valueFixed;
        participant[index] = obj;

        if (value === "" || re.test(value)) {
            let amountSum = 0;

            participant.map((i) => {
                if (i.amount === "") {
                    amountSum = 0;
                    participant[index].amount=0;
                } else {
                    amountSum = value;
                }
            });
            setPMADetails({
                ...pmaDetails,
                participants: participant,
                amount: amountSum,
            });
            setMessage("");

            setIsAmountError(false);
        }
        else {
            setPMADetails({
                ...pmaDetails,
                participants: participant,
            });
        }
        const tempTotalSum = pmaDetails.participants
            .map((x) => x.amount)
            .reduce((a, b) => parseFloat(a ? a : 0.00) + parseFloat(b ? b : 0.00), 0.00);
        setTotalSum(parseFloat(tempTotalSum).toFixed(2));
    };



    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        console.log("useEffect");
    }, [amountError, plaintiffError, pmaDetails]);
    const validate = (caseUnsettledCheck = false, tempresponse) => {

        let val = true;
        const details = tempresponse === undefined || tempresponse === null ? pmaDetails : tempresponse;
        if (caseUnsettledCheck) {
            //setIsFormSubmit(true);
            return val;
        } else if (
            !amountError &&
            !plaintiffError &&
            (details.partiesName !== null && details.partiesName !== '' && details.partiesName !== undefined) &&

            (details.notes !== null && details.notes !== '' && details.notes !== undefined) &&
            details.participants &&
            details.participants.length > 0 &&
            details.partiesName &&
            details.partiesName.trim().length > 0
        ) {
            if (
                details.participants !== undefined &&
                details.participants !== null
            ) {
                details.participants.forEach((y) => {
                    if (
                        y.plaintiffName.trim().length === 0 ||
                        y.defendantName.trim().length === 0
                    ) {
                        val = false;
                    }
                });
            }

            if (details.parties !== undefined && details.parties !== null) {
                details.parties.forEach((y) => {
                    if (y.email.trim().length === 0 || y.name.trim().length === 0) {
                        val = false;
                    }
                });
            }

            return val;
        } else {
            return false;
        }
    };

    const onCaseUnsettledChange = () => {
        if (!caseUnsettled) {
            if (pmaAdditionalNotes.indexOf("Case is unresolved") < 0) {
                setAdditionalNotes("Case is unresolved" + (pmaAdditionalNotes !== undefined && pmaAdditionalNotes !== null ? pmaAdditionalNotes : ""));
                pmaDetails.additionalNotes = "Case is unresolved" + (pmaAdditionalNotes !== undefined && pmaAdditionalNotes !== null ? pmaAdditionalNotes : "");
            }
            setMessage("");

        }
        else {
            setAdditionalNotes(pmaAdditionalNotes.replace("Case is unresolved", ""));
            pmaDetails.additionalNotes = pmaAdditionalNotes.replace("Case is unresolved", "");
        }        
        setcaseUnsettled(!caseUnsettled);
        setBtnHandle(!caseUnsettled)
        const isValidTemp = validate(!caseUnsettled);
        setValid(isValidTemp);
        // var elements = document.getElementsByClassName("prev-hearing");
        // for (var i in elements) {
        //     if (elements.hasOwnProperty(i)) {
        //         elements[i].style.display = !caseUnsettled ? "auto" : "none";
        //     }
        // }
        let tmpparties = pmaDetails.parties;
        tmpparties.map((item) => {
            item.isExcluded = !caseUnsettled ? false : true;
        });
        let tempError = [...newErrors];
        if (!caseUnsettled) {      
         tempError=[]; 
        }
        setNewErrors(tempError);
        setPMADetails({ ...pmaDetails, parties: tmpparties });
    };
    const handleswitchNewUploads = () => {
        setSwitchUploads(false);
        setReplaceFiles([]);
        setFile(null);
    };
    const handleSwitchHistory = () => {
        setSwitchUploads(true);
        setIsFileUpload(false);
        setUploadErrorMessage("");
        setReplaceFiles([]);
    };

    const viewResponse = (fileName) => {
        const url1 = `${GET_DECISION_FILEWITHSAS}?caseReference=${props.caseDetails["caseReference"]}&filename=${fileName}`;
        axios
            .get(url1, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res1) => {
                const byteString = atob(res1.data); // Add your base64-encoded byte string here

                // Convert byte string to Uint8Array
                const byteArray = new Uint8Array(byteString.length);
                for (let i = 0; i < byteString.length; i++) {
                    byteArray[i] = byteString.charCodeAt(i);
                }
        
                // Convert Uint8Array to Blob
                const blob = new Blob([byteArray], { type: 'application/pdf' }); 
                // Create a URL for the Blob
        const url = URL.createObjectURL(blob);
         setPreviewResponse(url);
                setIsPreviewResponse(true);
                setIsPreview(true);
            });
    };

    const deleteResponse = (showmessgae) => {
        setIsLoading(true);
        if (showmessgae === undefined || showmessgae === null) {
            showmessgae = true;
        }
        const url = `${DELETE_PMA_RESPONSE}?caseCode=${parseInt(
            props.caseDetails["caseCode"]
        )}`;
        const body = {
            caseCode: parseInt(props.caseDetails["caseCode"]),
        };
        axios
            .post(url, body, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res1) => {
                if (res1.status === 200) {
                    const url1 = `${DELETE_RESPONSE}/${props.caseDetails["caseReference"]}.pdf`;
                    axios
                        .get(url1, {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem("token")}`,
                            },
                        })
                        .then((res3) => {
                            if (res3.status === 200) {
                                setresponse(null);
                                setdecisionFileAvailable(false);
                                if (isShowDeleteMessage && showmessgae) {
                                    setdeletedResponse(true);
                                    setdeletedResponseMsg("PMA has been deleted successfully");
                                }
                            }
                        });
                }
                setIsLoading(false);
            });
    };

    const deleteAdditionalResponse = async (fileName, showmessgae) => {
        if(isPreviewResponse)
        {return;}
        if (showmessgae !== false) {
            setIsLoaded(false);
            if (showmessgae === undefined || showmessgae === null) { showmessgae = true; }
            const url = `${DELETE_PMA_ADDITIONAL_RESPONSE}?caseCode=${props.caseDetails["caseCode"]}&fileName=${fileName}`;
            const body = {
                caseCode: parseInt(props.caseDetails["caseCode"]),
            };
            axios
                .post(url, body, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                })
                .then((res1) => {
                    if (res1.status === 200) {
                        const additinalfiledata = additionalFile;
                        const indexadditinalfiledata = additinalfiledata.findIndex(
                            (x) => x.fileName === fileName
                        );
                        additinalfiledata.splice(indexadditinalfiledata, 1);
                        setAdditionalFile(additinalfiledata);
                        if (fileName === markAsPmaFile) {
                            setMarkAsPmaFile("");
                            setMarkAsPmaUploadFile("");
                            setMarkAsPmaDecisioFile("");
                            setdecisionFileAvailable(false);
                        }
                        const url1 = `${DELETE_ADDITIONAL_RESPONSE}/${props.caseDetails["caseReference"]}/${fileName}`;
                        axios
                            .get(url1, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                                },
                            })
                            .then((res3) => {
                                if (res3.status === 200) {
                                    if (isShowDeleteMessage && showmessgae) {
                                        setdeletedResponseMsg(
                                            "Additional Document has been deleted successfully"
                                        );
                                        setdeletedResponse(true);
                                    }
                                }
                            });
                    }
                    setIsLoaded(true);
                });
        }
        else {
            const url1 = `${DELETE_ADDITIONAL_RESPONSE}/${props.caseDetails["caseReference"]}/${fileName}`;
            axios
                .get(url1, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                })
                .then((res3) => {
                    if (res3.status === 200) {
                        if (isShowDeleteMessage && showmessgae) {
                            setdeletedResponseMsg(
                                "Additional Document has been deleted successfully"
                            );
                            setdeletedResponse(true);
                        }
                    }
                });
        }
    };
    const removeSignatoryError = (index)=>{
            const tempError = [...newErrors];
            let mainIndex = -1;
            tempError && tempError.forEach((elements,subIndex)=>{
                if(elements.rowIndex === index){
                    mainIndex = subIndex;
                }
           
            if(mainIndex !== -1){
                tempError.splice(mainIndex,1);
             
            }
        });
        tempError && tempError.forEach((elements,subIndex)=>{
            if(elements.rowIndex > index){
                elements.rowIndex = (elements.rowIndex - 1)
            }
        });
        
        setNewErrors(tempError);
        setSubmitRefresh((new Date().getTime()));
        setTimeout(function(){

        
            let docIndex = document.getElementById("signfocusInput");
            if(docIndex){ docIndex.click();}
        },200);
        
    }
    const deleteOnCancelPreview = async (fileName, showmessgae) => {
        if(isPreviewResponse)
        {return;}
        const url = `${DELETE_PMA_ADDITIONAL_RESPONSE}?caseCode=${props.caseDetails["caseCode"]}&fileName=${fileName}`;
        const body = {
            caseCode: parseInt(props.caseDetails["caseCode"]),
        };
        axios
            .post(url, body, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res1) => {
                if (res1.status === 200) {
                    const additinalfiledata = additionalFile;
                    const indexadditinalfiledata = additinalfiledata.findIndex(
                        (x) => x.fileName === fileName
                    );
                    if (indexadditinalfiledata === -1 && Utility.IsCaseDecision(props.caseDetails["caseStatus"])) {
                        setdecisionFileAvailable(false);
                    }
                    if (indexadditinalfiledata !== -1) {
                        additinalfiledata.splice(indexadditinalfiledata, 1);
                        setAdditionalFile(additinalfiledata);
                        if (fileName === markAsPmaFile) {
                            setMarkAsPmaFile("");
                            setMarkAsPmaUploadFile("")
                            setMarkAsPmaDecisioFile("");
                            setdecisionFileAvailable(false);
                        }
                    }
                }
            }).catch(function(error)
            {
                setMarkAsPmaFile("");
                setMarkAsPmaUploadFile("")
                setMarkAsPmaDecisioFile("");
                setdecisionFileAvailable(false);
                 
                  
                
            });
        const url1 = `${DELETE_ADDITIONAL_RESPONSE}/${props.caseDetails["caseReference"]}/${fileName}`;
        axios
            .get(url1, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res3) => {
                if (res3.status === 200) {
                    if (isShowDeleteMessage && showmessgae) {
                        setdeletedResponseMsg(
                            "Additional Document has been deleted successfully"
                        );
                        setdeletedResponse(true);
                        
                    }
                    
                }
            }).catch(function(error)
            { 
             
            });
    };

    const downloadResponse = async (fileName) => {
        const url1 = `${GET_DECISION_FILEWITHSAS}?caseReference=${props.caseDetails["caseReference"]}&filename=${fileName}`;
        axios
            .get(url1, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res1) => {
                // fetch(res1.data.result, {
                //     method: 'GET',
                //     headers: {
                //         'Content-Type': 'application/pdf',
                //     },
                // })
                //     .then(responseDownload => {
                const byteString = atob(res1.data); // Add your base64-encoded byte string here

                // Convert byte string to Uint8Array
                const byteArray = new Uint8Array(byteString.length);
                for (let i = 0; i < byteString.length; i++) {
                    byteArray[i] = byteString.charCodeAt(i);
                }

                // Convert Uint8Array to Blob
                const blob = new Blob([byteArray], { type: 'application/pdf' });

                // Create a URL for the Blob
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                // });
            });
    };

    const statuschek = (status,statusdate) => {

        if (status === 'sent') {
            return 'Sent for Signature '+new Date(statusdate).toLocaleDateString("en-US")+" "+ new Date(statusdate).toLocaleTimeString("en-US");
        }
        else if (status === 'completed') {
            return 'Signed '+new Date(statusdate).toLocaleDateString("en-US")+" "+ new Date(statusdate).toLocaleTimeString("en-US");
        }
        else if(status==='autoresponded') {
            return 'Failed to Deliver';
        }
        else
        {
            return status;
        }
    }
    useEffect(()=>{
          /* Check form validation by Dev*/
        if(submitRefresh > 0){
         
          setIsFormSubmit(true)
       }
    },[submitRefresh,pmaDetails]);
    //console.log("isFileSendForDocusign is " + isFileSendForDocusign);
    //console.log("voidenvelopeId is " + voidenvelopeId);
   // console.log("markAsPmaFile", markAsPmaFile, isFileUpload, isFormdisabled, TimesheetNotSubmitted);
    return (
        <div className={`${(decisionFileAvailable || isFormdisabled) ? "hide--required": ""}`}>
       
            <LoadingOverlay
                active={isLoading}
                spinner
                text="Please wait working on your request..."
            >
                {isLoaded ? (
                    <>
                        <div className="pma-page">
                            <div
                                className="text-left font-weight-bold"
                                style={{ padding: "5px 0px 7px" }}
                            >
                                Please fill in the below details
                            </div>
                        </div>
                        <div className="casestructure" id="htmldoc">
                            <CaseData caseDetails={props.caseDetails} />
                            <div className="mb-1s row">

                                {deletedResponse && (
                                    <Modal
                                        show={deletedResponse}
                                        onHide={() => setdeletedResponse(false)}
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title>PMA Document</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>{deletedResponseMsg}</Modal.Body>
                                        <Modal.Footer>
                                            <Button
                                                variant="secondary"
                                                onClick={() => setdeletedResponse(false)}
                                            >
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                )}

                                {isPreview && (
                                    <div className="col-12" style={{ marginTop: "1rem" }}>
                                        <Modal
                                            show={isPreview}
                                            className="previewmodal-content"
                                            onHide={cancelPreview}
                                        >
                                            <Modal.Header closeButton>
                                                <Modal.Title>Preview Document</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {/* New button Code... */}
                                                {isPreview && !isPreviewResponse && filebase64 && (
                                                    <div
                                                        className="mb-1s row"
                                                        style={{ marginBottom: "5px" }}
                                                    >
                                                        <div className="col-12">
                                                            <button
                                                                className="pmabtn3"
                                                                style={{ marginRight: "0rem" }}
                                                                onClick={sendForDocusign}
                                                            >
                                                                <i
                                                                    className="fa fa-check-circle"
                                                                    aria-hidden="true"
                                                                ></i>
                                                                Send For Docusign
                                                            </button>
                                                            <button
                                                                className="float-right pmabtn4"
                                                                style={{ marginRight: "1rem" }}
                                                                onClick={cancelPreview}
                                                            >
                                                                <i
                                                                    className="fa fa-times-circle"
                                                                    style={{ color: "red", marginRight: "10px" }}
                                                                    aria-hidden="true"
                                                                ></i>
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                                {/* New button Code End... */}
                                                {isPreviewResponse && (
                                                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.2.228/build/pdf.worker.min.js">
                                                        <div style={{ height: "720px" }}>
                                                            <Viewer
                                                                fileUrl={PreviewResponse}
                                                                plugins={[defaultInstance]}
                                                            />
                                                        </div>
                                                    </Worker>
                                                )}
                                                {!isPreviewResponse && (
                                                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.2.228/build/pdf.worker.min.js">
                                                        <div style={{ height: "720px" }}>
                                                            <Viewer
                                                                fileUrl={response}
                                                                plugins={[defaultInstance]}
                                                            />
                                                        </div>
                                                    </Worker>
                                                )}
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                )}

                                <div className="col-12">
                                   
                                    {isSubmitted && IsDocuSigned && filebase64 ? (
                                        <button
                                            className="pmabtn3"
                                            disabled={true}
                                            style={{ marginRight: "1rem" }}
                                        >
                                            <i className="fa fa-check-circle" aria-hidden="true"></i>
                                            Submitted
                                        </button>
                                    ) : (
                                        ""
                                    )}
                                    {pmaDetails.status === "sent" && !IsDocuSigned ? (
                                        <button
                                            className="float-right pmabtn2"
                                            disabled={true}
                                            style={{ marginRight: "1rem" }}
                                        >
                                            <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            ></i>
                                            Pending for E-Signature
                                        </button>
                                    ) : (
                                        ""
                                    )}                                   
                                     {/* Resubmitt form Code */}                                     
                                     {(decisionFileAvailable && !IsDocuSigned && voidenvelopeId) || (!IsDocuSigned && voidenvelopeId) || Utility.IsCaseClosed(props.caseDetails["caseStatus"])  ? (
                                        <button
                                            className={"pmaButton btn-primary"} style={{ float: "right", minWidth: "130px", margin: ".05rem 1rem 0" }}
                                            onClick={() => {
                                                setOpenConfirmDialog(true);
                                                setIsResubmit(true);
                                                setDeleteMessage(
                                                    `This will require the form to be reissued and signed again by the required signatories.`
                                                );
                                               
                                            }}>

                                            Recall PMA
                                        </button>
                                    ) : (
                                        ""
                                    )}
                                    {/* Resubmitt form Code End */}
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: "1rem" }} className="row">
                            
                            <div style={{ textAlign: "left" }}>
                                <button
                                    className={switchUploads ? "historybtn2" : "uploadbtn active"}
                                    onClick={handleswitchNewUploads}
                                >
                                    PMA
                                </button>
                                <button
                                    className={
                                        switchUploads ? "historybtn active" : "historybtn2"
                                    }
                                    style={{}}
                                    onClick={handleSwitchHistory}
                                >
                                    History
                                </button>
                            </div>
                        </div>
                        <div className={voidenvelopeId && !IsDocuSigned && !switchUploads?"border pmasec disablediv ": "border pmasec"}>
                            {/* this code is commented out because of no need to show consolidated decision file */}
                            {/*switchUploads &&
                                (response === null ||
                                    response === undefined ||
                                    response === "") && (
                                    <>
                                        <div
                                            className="row"
                                            style={{
                                                borderBottom: "1px solid",
                                                borderBottomColor: "lightgrey",
                                                borderTop: "1px solid",
                                                borderTopColor: "lightgrey",
                                            }}
                                        >
                                            <div className="col-md-4 col-3 text-left">
                                                Signed & Consolidated Documents
                                            </div>
                                            <div className="col-md-4 text-left">Date Uploaded</div>
                                            <div className="col-md-4 text-left">Action(s)</div>
                                            <hr style={{ width: "90%" }} className="solid" />
                                            <div className="col-md-4 col-9 response-submission">
                                                <div className="">
                                                    <span>Decision not submitted</span>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                        )*/}

                            {switchUploads && !voidenvelopeId && (
                                <>
                                    <div
                                        className="row"
                                        style={{
                                            borderBottom: "1px solid",
                                            borderBottomColor: "lightgrey",
                                            borderTop: "1px solid",
                                            borderTopColor: "lightgrey",
                                        }}
                                    >
                                        <div className="col-md-4 col-3 text-left">PMA Documents</div>
                                        <div className="col-md-4 text-left">Date Uploaded</div>
                                        <div className="col-md-4 text-left">Action(s)</div>
                                        <hr style={{ width: "90%" }} className="solid" />
                                        <div className="col-md-4 col-9 response-submission">
                                            <div className="">
                                                <span>Decision not submitted</span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {switchUploads && voidenvelopeId && (
                                <>
                                    <div
                                        className="row"
                                        style={{
                                            borderBottom: "1px solid",
                                            borderBottomColor: "lightgrey",
                                        }}
                                    >
                                        <div className="col-md-4 col-3 text-left">
                                            PMA Documents
                                        </div>
                                        <div className="col-md-4 text-left">Date Uploaded</div>
                                        <div className="col-md-4 text-left">Action(s)</div>
                                        <hr style={{ width: "90%" }} className="solid" />
                                        <div className="col-md-4 response-submission text-left">
                                            <span className="action">
                                                {" "}
                                                <i
                                                    style={{ color: "#DC1D00" }}
                                                    className="fa fa-file-pdf-o"
                                                ></i>
                                            </span>
                                            <span className="file-name">
                                                {`${props.caseDetails["caseReference"]}.pdf`}
                                            </span>
                                        </div>
                                        <div className="col-md-4 response-submission text-left">
                                            <span>{pmaDetails.decisionFileUpdate && Utility.getdate(pmaDetails.decisionFileUpdate)}</span>
                                        </div>
                                        <div className="col-md-4 response-submission text-left">
                                            <span
                                                className="action"
                                                onClick={() => viewResponse(`${props.caseDetails["caseReference"]}_decision.pdf`)}
                                            >
                                                <i className="fa fa-eye"></i>
                                            </span>

                                            <span
                                                className="action"
                                                onClick={() => {
                                                    downloadResponse(
                                                        `${props.caseDetails["caseReference"]}_decision.pdf`
                                                    );
                                                }}
                                            >
                                                <i className="fa fa-download"></i>
                                            </span>
                                            {/* {((decisionFileAvailable) && !IsDocuSigned) */}
                                            {!((decisionFileAvailable && !IsDocuSigned && voidenvelopeId) || (!IsDocuSigned && voidenvelopeId)) 
                                             && (<span
                                                className="action"
                                                onClick={() => {
                                                    //// deleteResponse
                                                    {
                                                        setOpenConfirmDialog(true);
                                                        setCaseReference(true);
                                                        setlastDeleteMessage('caseReference')
                                                        setDeleteMessage(
                                                            `Are you sure you want to delete this file: ${props.caseDetails["caseReference"]}.pdf`
                                                        );
                                                    }
                                                }}
                                            >
                                                <i className="fa fa-trash"></i>
                                            </span>)}
                                        </div>
                                    </div>
                                </>
                            )}

                            {switchUploads && additionalFile && (
                                <div id="htmldoc2">
                                    <div className="row">
                                        <div className="col-md-4 text-left">
                                            Additional Document(s)
                                        </div>
                                        <div className="col-md-4 text-left">Date Uploaded</div>
                                        <div className="col-md-4 text-left">Action(s)</div>
                                        <hr style={{ width: "90%" }} className="solid" />
                                        {additionalFile !== null && additionalFile.length > 0 && isFileSendForDocusign ? (
                                            additionalFile?.map((item, index) => {
                                                return (
                                                    markAsPmaFile !== item.name && (
                                                        <div className="row" style={{ margin: "0", padding: "0" }}>
                                                            <div
                                                                className="col-md-4 response-submission text-left"
                                                                style={{
                                                                    color:
                                                                        item?.isOnlineDecisionFile
                                                                            ? "blue"
                                                                            : "gray",
                                                                }}
                                                            >
                                                                <span className="action">
                                                                    {" "}
                                                                    <i
                                                                        style={{
                                                                            color:
                                                                                item?.fileName?.indexOf(".pdf") !== -1
                                                                                    ? "#DC1D00"
                                                                                    : "blue",
                                                                        }}
                                                                        className={
                                                                            item?.fileName?.indexOf(".pdf") !== -1
                                                                                ? "fa fa-file-pdf-o"
                                                                                : "fa fa-file-word-o"
                                                                        }
                                                                    ></i>
                                                                </span>
                                                                <span className="file-name">
                                                                    {item?.fileName === markAsPmaFile
                                                                        ? "[PMA File]"
                                                                        : ""}
                                                                    &nbsp; &nbsp;{item.fileName}
                                                                </span>
                                                            </div>
                                                            <div className="col-md-4 response-submission text-left">
                                                                {Utility.getdate(item.updateDate)}
                                                            </div>
                                                            <div
                                                                className="col-md-4 response-submission text-left"
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                <span
                                                                    className="action"
                                                                    onClick={() => viewResponse(item.fileName)}
                                                                >
                                                                    <i className="fa fa-eye"></i>
                                                                </span>
                                                                &nbsp; &nbsp;
                                                                <span
                                                                    className="action"
                                                                    onClick={() => {
                                                                        downloadResponse(
                                                                            item.fileName
                                                                        );
                                                                    }}
                                                                >
                                                                    <i className="fa fa-download"></i>
                                                                </span>
                                                                {!((decisionFileAvailable && !IsDocuSigned && voidenvelopeId) || (!IsDocuSigned && voidenvelopeId)) && (<>
                                                                    &nbsp; &nbsp;
                                                                    <span
                                                                        className="action"
                                                                        onClick={(e, v) => {
                                                                            setOpenConfirmDialog(true);
                                                                            setAdditionalFileRemove(item.fileName);
                                                                            setlastDeleteMessage('deleteAdditionalResponse')
                                                                            setDeleteMessage(
                                                                                `Are you sure you want to delete this file: ${item.fileName}`
                                                                            );
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-trash"></i>
                                                                    </span>
                                                                </>)}
                                                            </div>
                                                        </div>
                                                    )
                                                );
                                            })
                                        ) : (
                                            <div className="col-md-4">
                                                No additional document(s) are found
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            {switchUploads === false && IsDocuSigned && response !== null && response !== undefined && response !== '' && (
                                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.2.228/build/pdf.worker.min.js">
                                    <div style={{ overflow: 'scroll', }}>
                                        <Viewer
                                            fileUrl={response}
                                            plugins={[defaultInstance]}
                                        />
                                    </div>
                                </Worker>
                            )}
                            {switchUploads === false && !IsDocuSigned &&
                                (
                                    <>
                                        <>
                                            <span
                                                style={{ color: "red" }}
                                                className="error-message error"
                                            >
                                                {message}
                                            </span>
                                            <>
                                                
                                            </>

                                            <div className="" id="htmldoc2"   >
                                                {props.caseDetails["isPaid"] ? <></> : (!Utility.IsCaseDecision(props.caseDetails["caseStatus"]) && TimesheetNotSubmitted ? (
                                                    <div className="row">
                                                        <div style={{ display: "flex", textAlign: "left" }}>
                                                            <span style={{ color: "red" }}>
                                                                You must submit timesheet before proceeding with
                                                                the PMA submission.
                                                            </span>
                                                        </div>
                                                    </div>
                                                ) : <></>)}
                                                {!Utility.IsCaseClosed(props.caseDetails["caseStatus"]) && decisionFileAvailable!== null &&
                                                    decisionFileAvailable !== "" &&
                                                    decisionFileAvailable !== undefined && decisionFileAvailable===true  && (
                                                        <div className="row">
                                                            <div style={{ display: "flex", textAlign: "left" }}>
                                                                <span style={{ color: "red" }}>
                                                                    The on-line decision form is unavailable as a
                                                                    decision document has already been uploaded.
                                                                    <br />
                                                                    If required, please use File History below to
                                                                    delete the uploaded decision document.
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                <div className="row">
                                                    <div style={{ display: "flex" }}>
                                                        <input
                                                            type="checkbox"
                                                            style={{ marginRight: "5px" }}
                                                            onChange={onCaseUnsettledChange}
                                                            checked={caseUnsettled}
                                                            disabled={(Utility.IsCaseClosed(props.caseDetails["caseStatus"]) || isFormdisabled || decisionFileAvailable)}
                                                        />
                                                        Case Unresolved
                                                    </div>
                                                </div>
                                                <div>
                                                    <span className="text-left">
                                                        <div>
                                                        <input type="text" id="focusInput" onFocus={SaveData} onBlur={SaveData}   onClick={SaveData}  />
                                                            The parties hereto,
                                                            <input
                                                                className="input2"
                                                               
                                                                disabled={decisionFileAvailable || isFormdisabled || caseUnsettled}
                                                                name="partiesName"
                                                                ref={ref}
                                                                onKeyDown={onkeydown}
                                                                onChange={onPartiesHereToChange}
                                                                type="text"
                                                                style={{ width: "45rem", paddingLeft: "5px" }}
                                                                value={pmaDetails["partiesName"]}
                                                                onBlur={SaveData}
                                                            />

                                                            , having submitted the facts and issues to NAM, and
                                                            the mutually agreed mediator,
                                                            <br />
                                                            <input
                                                                className="input2"
                                                                type="text"
                                                                readOnly
                                                                style={{ width: "45rem", paddingLeft: "5px" }}
                                                                value={props.caseDetails["hearingOfficerName"]}
                                                            />
                                                            , have fully and completely resolved the dispute as
                                                            follows:
                                                            {Object.keys(pmaDetails).length !== 0 &&
                                                                pmaDetails?.participants &&
                                                                pmaDetails.participants !== undefined &&
                                                                pmaDetails.participants !== null ? (
                                                                pmaDetails.participants.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            <div style={{ marginTop: "0.5rem" }}>
                                                                            <div
                                                                                    className={`row ${(btnHandle || caseUnsettled) ? "hideerror":""}  ${(caseUnsettled) ? "hide--required":""}`}
                                                                                    style={{ marginTop: "1rem" }}
                                                                                >
                                                                                    <div className="col-12">
                                                                                    <div className="" style={{marginBottom:15}}>
                                                                                <span style={{ color: "#f00",display: "contents" }} className="custom--required">
                                                                                       *&nbsp; &nbsp;
                                                                                    </span>                                                                               
                                                                                    <input
                                                                                        maxLength={100}
                                                                                        className={item.HilightDefendent ? "input2notValidDefendent" : "input2"}
                                                                                        required
                                                                                        type="text"
                                                                                       
                                                                                        disabled={

                                                                                            decisionFileAvailable || isFormdisabled || caseUnsettled
                                                                                        }
                                                                                        style={{ width: "98%", borderColor:`${item.HilightDefendent? "red":"grey"}` }}
                                                                                        placeholder="Defendant Name"
                                                                                        onKeyDown={onkeydown}
                                                                                        onChange={(event) =>
                                                                                            onDefendantChange(event, index)
                                                                                        }
                                                                                        value={item.defendantName}
                                                                                        onBlur={SaveData}
                                                                                        onKeyUp={SaveData}
                                                                                    />
                                                                                </div>
                                                                                    </div>
                                                                                    <div className="col-10 plainName">
                                                                                <div className="row"> 
                                                                                <div className="col-9">
                                                                                    
                                                                               
                                                                                        shall pay to <span style={{ color: "#f00",top:0 }} className="custom--required">
                                                                                       *&nbsp; &nbsp;
                                                                                    </span>
                                                                                        <input
                                                                                            maxLength={100}
                                                                                            className={item.hilightPlantiff ? "input2notValidPlantiff" : "input2"}
                                                                                            required
                                                                                            type="text"
                                                                                            disabled={

                                                                                                decisionFileAvailable || isFormdisabled || caseUnsettled
                                                                                            }
                                                                                            style={{
                                                                                                marginLeft: "5px",
                                                                                                width: "87%",
                                                                                                paddingLeft: "5px",
                                                                                                borderColor:`${item.hilightPlantiff? "red":"grey"}`
                                                                                            }}
                                                                                            placeholder="Plaintiff Name"
                                                                                            value={item.plaintiffName}
                                                                                            onKeyDown={onkeydown}
                                                                                            onChange={(event) =>
                                                                                                onPlaintiffNameChange(
                                                                                                    event,
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                            onBlur={SaveData}
                                                                                            onKeyUp={SaveData}
                                                                                        />
                                                                                        , 
                                                                                        <div style={{ marginLeft: "0.85rem" }}>
                                                                                <div>
                                                                                    <span
                                                                                        style={{ color: "red" }}
                                                                                        className="error-message error"
                                                                                    >
                                                                                        {item.errorMessage}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-3">
                                                                                        <p className="amountInput">
                                                                                        <span className="custom--required" style={{color:"red"}}>*</span>
                                                                                        <span className="amountSymbol">${" "}</span> <NumberFormat
                                                                                            allowNegative={false}
                                                                                            onValueChange={(event) =>{
                                                                                                onValueChange(event, index);
                                                                                                handleAmtPayError(event,"m"+index);
                                                                                            }}
                                                                                            disabled={

                                                                                                decisionFileAvailable || isFormdisabled || caseUnsettled
                                                                                            }
                                                                                            style={{ width: "100%" }}
                                                                                            required
                                                                                            thousandSeparator={true}
                                                                                            decimalScale={2}
                                                                                            fixedDecimalScale={true}
                                                                                            name="amtPayable"
                                                                                            value={item.amount}
                                                                                            onBlur={SaveData}
                                                                                            onKeyUp={SaveData}
                                                                                        />
                                                                                         {(plainTiffDefentError && plainTiffDefentError.hasOwnProperty("m"+index)) && 
                                                                                            <span className="error-message error" style={{color:"red"}}>{plainTiffDefentError["m"+index]}</span>
                                                                                        }
                                                                                        </p>
                                                                                        </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        {index !== 0 && !decisionFileAvailable && (
                                                                                            !isFormdisabled && !caseUnsettled && (
                                                                                                <span
                                                                                                    className="action"
                                                                                                    onClick={(event) => {
                                                                                                        setOpenConfirmDialog(true);
                                                                                                        
                                                                                                        
                                                                                                        setDeleteIndexDefendantPantiffError(
                                                                                                            index,event
                                                                                                        );
                                                                                                        setDeleteMessage(
                                                                                                            "Are you sure you want to delete this record ?"
                                                                                                        );
                                                                                                        
                                                                                                    }}
                                                                                                    
                                                                                                >
                                                                                                    <i className="fa fa-trash"></i>
                                                                                                    
                                                                                                </span>)
                                                                                                
                                                                                        )}
                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </>
                                                                    );
                                                                })
                                                            ) : (
                                                                <></>
                                                            )}
                                                            <br></br>
                                                            {totalSum !== "0.00" && <>
                                                                <div className="row" style={{ marginBottom: ".5rem" }}>
                                                                    <div className="col-md-6">
                                                                        <b>Total Sum</b>: <b>$ <NumberFormat
                                                                            allowNegative={false}
                                                                            disabled={true}
                                                                            style={{ width: "13%" }}
                                                                            thousandSeparator={true}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                            value={totalSum}
                                                                            displayType={'text'}
                                                                        /></b>
                                                                    </div>
                                                                    <div className="col-md-6"></div>
                                                                </div>
                                                                <br></br></>
                                                            }
                                                            <>
                                                                <button
                                                                    className={`additionalbtn2 ${(decisionFileAvailable || isFormdisabled) ? 'disabledBtn':''}`}
                                                                    style={{
                                                                        width: "10%",
                                                                        margin: "10px 10px 10px 0px",
                                                                    }}
                                                                    disabled={
                                                                        decisionFileAvailable || isFormdisabled || caseUnsettled
                                                                    }
                                                                    onClick={handleAddContainer}
                                                                >
                                                                    +Add More
                                                                </button>
                                                            </>
                                                            inclusive of all costs, disbursements, liens, claims
                                                            and attorney fees for damages, whether compensatory,
                                                            liquidated and/or punitive. All payments shall be made{" "}
                                                            <p className={`noLaterField ${(btnHandle ||caseUnsettled ) ? 'hideerror':''} ${(caseUnsettled) ? "hide--required":""}`}>
                                                            <span className="custom--required" style={{color:"red"}}>*</span>
                                                            <input
                                                                type="text"
                                                                maxLength={300}
                                                                disabled={decisionFileAvailable || isFormdisabled || caseUnsettled}
                                                                name="noLater"
                                                                onChange={noLaterHandler}
                                                                onKeyUp={SaveData}
                                                                value={pmaDetails.notes===undefined || pmaDetails.notes ===null?"pursuant to the CPLR":pmaDetails.notes}
                                                            />
                                                            {(plainTiffDefentError && plainTiffDefentError.hasOwnProperty("noLater")) && 
                                                                 <span className="error-message error" style={{color:"red"}}>{plainTiffDefentError["noLater"]}</span>
                                                            }
                                                            </p>
                                                            <div className="text-left font-weight-bold">
                                                                <div>Additional Notes</div>
                                                               

                                                                    <textarea
                                                                        style={{ width: "100%", lineHeight: "20px" }}
                                                                        disabled={decisionFileAvailable || isFormdisabled}
                                                                        name="additionalNotes"
                                                                        spellCheck={true}
                                                                        onChange={additionalNoteshandler}
                                                                        value={pmaDetails.additionalNotes}
                                                                        required={caseUnsettled}
                                                                        onBlur={(e) => { SaveData(e); setPMADetails({ ...pmaDetails, additionalNotes: pmaDetails.additionalNotes }); }}
                                                                    />
                                                                
                                                                {isadditionaNotesError && (
                                                                    <span style={{ color: "red" }}>
                                                                        Additional notes are required
                                                                    </span>
                                                                )}
                                                            </div>
                                                        <div>
                                                            {props.caseDetails["hearingStatus"]=='CONTINUED'&&(
                                                            <span>This case is to be Continued.
                                                            </span>
                                                            )}

                                                        </div>
                                                            
                                                            That no party to this agreement shall at anytime
                                                            hereafter make any claims against the other,
                                                            institute any lawsuit against the other, or make any
                                                            demands for payment from the other for any alleged
                                                            reason or cause arising out of the facts and issues
                                                            of the matter herein. Each party releases the other
                                                            from any and all claims and/or liability arising
                                                            from this matter.
                                                            <br />
                                                            <br />
                                                            That this agreement is final and binding upon any
                                                            and all parties to this matter and is enforceable in
                                                            any court of law of general jurisdiction.
                                                        </div>
                                                        {pmaDetails.timeSheets !== undefined &&
                                                            pmaDetails.timeSheets !== null &&
                                                            pmaDetails.timeSheets.length > 0 && (
                                                                <>
                                                                    <div>
                                                                        <span style={{ fontWeight: "bold" }}>
                                                                            Timesheet Details:
                                                                        </span>

                                                                        <table className="decision-timesheet table1">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col">Hearing date</th>
                                                                                    {/* <th scope="col">Start time</th>
                                                                                    <th scope="col">End time</th> */}
                                                                                    <th scope="col">Time (ET)</th>

                                                                                    <th scope="col">Reserved time</th>
                                                                                    <th scope="col">
                                                                                        Review of documents
                                                                                    </th>
                                                                                    <th scope="col">
                                                                                        Post mediation time
                                                                                    </th>
                                                                                    <th scope="col">Total hearing time</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className="font-weight-bold">
                                                                                {pmaDetails.timeSheets.map(
                                                                                    (timesheet, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <tr
                                                                                                    // style={{
                                                                                                    //     display: caseUnsettled
                                                                                                    //         ? timesheet.hearingCode ==
                                                                                                    //             hearingCode
                                                                                                    //             ? "table-row"
                                                                                                    //             : "none"
                                                                                                    //         : "table-row",
                                                                                                    // }}
                                                                                                    className={`${timesheet.hearingCode ==
                                                                                                        hearingCode
                                                                                                        ? "current-hearing"
                                                                                                        : "prev-hearing"
                                                                                                        }`}
                                                                                                >
                                                                                                    <td data-label="Hearing Date">
                                                                                                        {Utility.getdate(
                                                                                                            timesheet.hearingDate
                                                                                                        )}
                                                                                                    </td>
                                                                                                    {/* <td data-label="Start Time">
                                                                                                        {Utility.ConvertDate(
                                                                                                            timesheet.hearingStartTime
                                                                                                        )}
                                                                                                    </td> */}
                                                                                                    <td data-label="End Time">

                                                                                                        {timesheet.isCombinedTimeSheet ? <span>
                                                                                                            <span>{"Session 1 : " + (
                                                                                                                timesheet.hearingStartTimeUTC &&
                                                                                                                new Date(
                                                                                                                    (timesheet.hearingStartTimeUTC.indexOf("GMT") ?
                                                                                                                    Utility.convertUTCDateToUserTimeZoneOfPMADecision(timesheet.hearingStartTimeUTC) :
                                                                                                                    Utility.convertUTCDateToUserTimeZoneOfPMADecision(new Date(timesheet.hearingStartTimeUTC).toUTCString())
                                                                                                                )
                                                                                                                ).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")
                                                                                                                                                                                                                            
                                                                                                            ) + " to " + (timesheet.hearingEndTimeUTC && 
                                                                                                                new Date(
                                                                                                                    (timesheet.hearingEndTimeUTC.indexOf("GMT") ?
                                                                                                                    Utility.convertUTCDateToUserTimeZoneOfPMADecision(timesheet.hearingEndTimeUTC) :
                                                                                                                    Utility.convertUTCDateToUserTimeZoneOfPMADecision(new Date(timesheet.hearingEndTimeUTC).toUTCString()))
                                                                                                                    
                                                                                                                ).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")
                                                                                                                
                                                                                                                    )}
                                                                                                            </span> <br></br>
                                                                                                            <span>{"Session 2 : " + (timesheet.hearingStartTimeAdditionalUTC && 
                                                                                                           new Date(
                                                                                                            (timesheet.hearingStartTimeAdditionalUTC.indexOf("GMT") ?
                                                                                                                Utility.convertUTCDateToUserTimeZoneOfPMADecision(timesheet.hearingStartTimeAdditionalUTC) :
                                                                                                                Utility.convertUTCDateToUserTimeZoneOfPMADecision(new Date(timesheet.hearingStartTimeAdditionalUTC).toUTCString())
                                                                                                            )
                                                                                                           ).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")
                                                                                                        
                                                                                                        ) + " to " + (timesheet.hearingEndTimeAdditionalUTC && 
                                                                                                            
                                                                                                            new Date(
                                                                                                                (timesheet.hearingEndTimeAdditionalUTC.indexOf("GMT") ?
                                                                                                                Utility.convertUTCDateToUserTimeZoneOfPMADecision(timesheet.hearingEndTimeAdditionalUTC) :
                                                                                                                Utility.convertUTCDateToUserTimeZoneOfPMADecision(new Date(timesheet.hearingEndTimeAdditionalUTC).toUTCString()))
                                                                                                            ).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")
                                                                                                            
                                                                                                                )}</span>
                                                                                                        </span> :

                                                                                                            ((
                                                                                                                timesheet.hearingStartTimeUTC && 
                                                                                                                new Date(
                                                                                                                    (timesheet.hearingStartTimeUTC.indexOf("GMT") ?
                                                                                                                    Utility.convertUTCDateToUserTimeZoneOfPMADecision(timesheet.hearingStartTimeUTC) :
                                                                                                                    Utility.convertUTCDateToUserTimeZoneOfPMADecision(new Date(timesheet.hearingStartTimeUTC).toUTCString())
                                                                                                                )
                                                                                                                ).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")
                                                                                                            )
                                                                                                                + "" + (timesheet.hearingDuration !== null && timesheet.hearingDuration > 0
                                                                                                                    && timesheet.hearingDuration > parseFloat(timesheet?.hearingScheduledDuration) * 60 ? timesheet?.hearingScheduledDuration != 0 ?
                                                                                                                    (" to " + ((timesheet.hearingEndTimeUTC && 
                                                                                                                        new Date(
                                                                                                                            (timesheet.hearingEndTimeUTC.indexOf("GMT") ?
                                                                                                                        Utility.convertUTCDateToUserTimeZoneOfPMADecision(timesheet.hearingEndTimeUTC) :
                                                                                                                        Utility.convertUTCDateToUserTimeZoneOfPMADecision(new Date(timesheet.hearingEndTimeUTC).toUTCString()))
                                                                                                                    
                                                                                                                        ).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")
                                                                                                                        
                                                                                                                    ))) : "" : ""))

                                                                                                        }

                                                                                                       

                                                                                                    </td>
                                                                                                    <td data-label="Reserved time">
                                                                                                       
                                                                                                        {Utility.toHoursAndMinutes(parseFloat(timesheet?.hearingScheduledDuration) * 60)}
                                                                                                    </td>
                                                                                                    <td data-label="Review of documents & preparation of decision time">
                                                                                                        {timesheet.revDocTimehours?
                                                                                                        timesheet.revDocTimehours+" Hours "+timesheet.revDocTimeminutes+" Minutes":
                                                                                                        Utility.toHoursAndMinutes(parseFloat(timesheet.revDocTimeminutes))}
                                                                                                        
                                                                                                    </td>
                                                                                                    <td data-label="Post mediation time">
                                                                                                        {/* {timesheet.postMedTimehours}{" "}
                                                                                                        Hours{" "}
                                                                                                        {timesheet.postMedTimeminutes}{" "}
                                                                                                        Minutes */}
                                                                                                        {timesheet.postMedTimehours?
                                                                                                        timesheet.postMedTimehours+" Hours "+timesheet.postMedTimeminutes+" Minutes":
                                                                                                        Utility.toHoursAndMinutes(parseFloat(timesheet.postMedTimeminutes))}
                                                                                                    </td>
                                                                                                    <td data-label="Total hearing time">
                                                                                                        {getTotalTime(timesheet)}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </tbody>
                                                                        </table>
                                                                        <div
                                                                            style={{
                                                                                display: caseUnsettled ? "none" : "block",
                                                                            }}
                                                                        >
                                                                            Total time spent on this case:
                                                                        </div>
                                                                        <span
                                                                            className="font-weight-bold"
                                                                            style={{
                                                                                color: "#337AB7",
                                                                                display: caseUnsettled ? "none" : "block",
                                                                            }}
                                                                        >
                                                                            {Utility.toHoursAndMinutes(
                                                                                props.caseDetails?.caseDuration
                                                                            )}
                                                                           { /* totalDuration*/}
                                                                        </span>
                                                                    </div>
                                                                </>
                                                            )}
                                                        <div className="signatureBtmLine">
                                                            <div className="row ">
                                                                <div className="col-md-9 col-12">
                                                                    <div className="text-left font-weight-bold">
                                                                        The signature reflects the Parties' agreement
                                                                        with the time spent.
                                                                    </div>
                                                                    <div>
                                                                        <label
                                                                            htmlFor="staticEmail"
                                                                            className="right"
                                                                        >
                                                                            Dated on this{" "}
                                                                            <span style={{ fontWeight: "bold" }}>
                                                                                {dateString}
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    <div className={`${btnHandle ? "":""}`}>
                                                            {pmaDetails["parties"] !== undefined &&
                                                                pmaDetails["parties"] !== null
                                                                ?<>
                                                                 <div className="row includeDiv includeDiv">
                                                                                    <div className="col-sm-1 col-12"></div>
                                                                                    <div className="col-sm-3 col-12"></div>
                                                                                    <div className="col-sm-5 col-12 ">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-9 col-12"></div>
                                                                                            <div className="col-sm-3 col-12 text-center"> <label
                                                                                                className="pmaSignatoryCheckbox"
                                                                                               
                                                                                                for="flexCheckDefault"
                                                                                            >
                                                                                                <b>Include Signatories</b>
                                                                                            </label></div>
                                                                                        </div>
                                                                                           
                                                                                        </div>
                                                                                    <div className="col-sm-2 col-12 text-center">  <b>DocuSign Status</b></div>
                                                                                    <div className="col-sm-1 col-12"></div>
                                                                                </div>
                                                                {( Array.from(pmaDetails["parties"]).map(
                                                                    (c, index) => {
                                                                        return (
                                                                            <>
                                                                               {console.log("pmaDetails.parties",pmaDetails.parties)}
                                                                                {pmaDetails.parties[index].type ===
                                                                                    "Additional Signatory" &&
                                                                                    index === partyLength && (
                                                                                        <hr className="hr hr-blurry" />
                                                                                    )}
                                                                                
                                                                                <div className="mb-1 row includeDiv mtp20">
                                                                                    <div className="col-sm-1 col-12">
                                                                                        <label>
                                                                                            {pmaDetails.parties[index].type} 
                                                                                        </label>
                                                                                      
                                                                                    </div>
                                                                                    <div className="col-sm-3 col-12">
                                                                                        <input
                                                                                            
                                                                                            onKeyDown={onkeydown}
                                                                                            onChange={(event) =>{
                                                                                               
                                                                                                handleOnNameChange(event, index)
                                                                                            }
                                                                                            }
                                                                                            onMouseLeave={(event) =>{
                                                                                                handleOnNameChange(event, index);
                                                                                                   SaveData(event);
                                                                                                 }
                                                                                            }
                                                                                            id={`pmaName${index}`}
                                                                                            key={`${index}` - "pmaName"}
                                                                                            className="pmaName"
                                                                                           
                                                                                            pmavalue={
                                                                                                pmaDetails.parties[index].name
                                                                                            }
                                                                                            type="text"
                                                                                            disabled={

                                                                                                Utility.IsCaseClosed(props.caseDetails["caseStatus"])||
                                                                                              
                                                                    ((voidenvelopeId!=='' &&voidenvelopeId!==null && voidenvelopeId!==undefined)&& pmaDetails.status === "sent")


                                                                                            }
                                                                                            value={
                                                                                                (pmaDetails.parties[index].name && ((pmaDetails.parties[index].name).trim()).length >= 100) ? (pmaDetails.parties[index].name.trim()).substr(0,100): pmaDetails.parties[index].name
                                                                                            }
                                                                                            placeholder="Name"
                                                                                            onBlur={SaveData}
                                                                                            
                                                                                        />
                                                                                        <div>
                                                                                           
                                                                                            {newErrors.length > 0 &&
                                                                                                index ===
                                                                                                (newErrors?.filter(
                                                                                                    (ix) => ix.rowIndex === index
                                                                                                ))[0]?.rowIndex && (
                                                                                                    <div>
                                                                                                        <span
                                                                                                            style={{ color: "red" }}
                                                                                                            className="error-message error"
                                                                                                        >
                                                                                                            {!caseUnsettled && newErrors.length > 0  &&
                                                                                                                newErrors
                                                                                                                    ?.filter(
                                                                                                                        (o) =>
                                                                                                                            o.rowIndex === index
                                                                                                                    )
                                                                                                                    .map((o) => o.errorname)
                                                                                                                    .join(" ")}

                                                                                                                    {caseUnsettled && pmaDetails.parties[index]
                                                                                                        .isExcluded &&  newErrors.length > 0  &&
                                                                                                                newErrors
                                                                                                                    ?.filter(
                                                                                                                        (o) =>
                                                                                                                            o.rowIndex === index
                                                                                                                    )
                                                                                                                    .map((o) => o.errorname)
                                                                                                                    .join(" ")}


                                                                                                        </span></div>)}
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="col-sm-5 col-12">
                                                                                        <div className="row"><div className="col-sm-9 col-12">                                
                                                                                        <input
                                                                                            maxLength={100}
                                                                                            onChange={(event) =>
                                                                                                handleOnChange(event, index)
                                                                                            }
                                                                                            onMouseLeave={(event) =>{
                                                                                                handleOnChange(event, index);
                                                                                                 SaveData(event);
                                                                                            }
                                                                                            }
                                                                                            id={index}
                                                                                            key={`${index}` - "email"}
                                                                                            className="pmaEmail chckbox"
                                                                                            pmavalue={
                                                                                                pmaDetails.parties[index].email
                                                                                            }
                                                                                            type="email"
                                                                                            placeholder="Email"
                                                                                            disabled={

                                                                                                Utility.IsCaseClosed(props.caseDetails["caseStatus"])||(    (voidenvelopeId!=='' &&voidenvelopeId!==null && voidenvelopeId!==undefined)&& pmaDetails.status === "sent")


                                                                                            }
                                                                                            value={
                                                                                                pmaDetails.parties[index].email.indexOf(",")!==-1? 
                                                                                                pmaDetails.parties[index].email.split(",")[0] : 
                                                                                                (pmaDetails.parties[index].email.indexOf(";")!==-1 ? 
                                                                                                pmaDetails.parties[index].email.split(";")[0] : pmaDetails.parties[index].email)
                                                                                            }
                                                                                            onBlur={SaveData}
                                                                                        />
                                                                                        </div>
                                                                                        <div className="col-sm-3 col-12 text-center">
                                                                                        <label
                                                                                            className={"pmaSignatoryCheckbox"}
                                                                                        >
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                disabled={

                                                                                           
                                                                                                    Utility.IsCaseClosed(props.caseDetails["caseStatus"])||(  (voidenvelopeId!=='' &&voidenvelopeId!==null && voidenvelopeId!==undefined)&& pmaDetails.status === "sent")


                                                                                                }
                                                                                                name={index}
                                                                                                defaultChecked={
                                                                                                    pmaDetails.parties[index]
                                                                                                        .isExcluded
                                                                                                }
                                                                                                checked={
                                                                                                    pmaDetails.parties[index]
                                                                                                        .isExcluded
                                                                                                }
                                                                                                onChange={(event) =>
                                                                                                    excludeClickHandler(
                                                                                                        event,
                                                                                                        index
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                            {
                                                                                                pmaDetails.parties[index]
                                                                                                    .isExcluded
                                                                                            }
                                                                                        </label>
                                                                                        </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                        <div className="col-sm-12 col-12" style={{ marginLeft: '4.2rem', }}>
                                                                                            {newErrors.length > 0 &&
                                                                                                index ===
                                                                                                (newErrors?.filter(
                                                                                                    (ix) => ix.rowIndex === index
                                                                                                ))[0]?.rowIndex && (
                                                                                                    <div>
                                                                                                        <span
                                                                                                            style={{ color: "red" }}
                                                                                                            className="error-message error"
                                                                                                        >
                                                                                                            {!caseUnsettled && newErrors.length > 0 &&
                                                                                                                newErrors
                                                                                                                    ?.filter(
                                                                                                                        (o) =>
                                                                                                                            o.rowIndex === index
                                                                                                                    )
                                                                                                                    .map((o) => o.erroremail)
                                                                                                                    .join(" ")}

                                                                                                            {caseUnsettled && pmaDetails.parties[index]
                                                                                                                .isExcluded && newErrors.length > 0 &&
                                                                                                                newErrors
                                                                                                                    ?.filter(
                                                                                                                        (o) =>
                                                                                                                            o.rowIndex === index
                                                                                                                    )
                                                                                                                    .map((o) => o.erroremail)
                                                                                                                    .join(" ")}
                                                                                                        </span></div>)}
                                                                                        </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-sm-2 col-12 text-center">
                                                                                        <label className="" style={{color: pmaDetails.signingParties.find(x => x.email === pmaDetails.parties[index].email)?.status ==='autoresponded'?"red" : "balck"}}>

                                                                                            {
                                                                                                statuschek(pmaDetails.signingParties.find(x => x.email === pmaDetails.parties[index].email)?.status,pmaDetails.signingParties.find(x => x.email === pmaDetails.parties[index].email)?.statusDate)

                                                                                            }
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-sm-1 col-12">
                                                                                        {!(Utility.IsCaseClosed(props.caseDetails["caseStatus"])||((voidenvelopeId!=='' &&voidenvelopeId!==null && voidenvelopeId!==undefined)&& pmaDetails.status === "sent") )&& (
                                                                                            <span
                                                                                                className="action"
                                                                                                onClick={() => {
                                                                                                    setOpenConfirmDialog(true);
                                                                                                   // removeSignatoryError(index);
                                                                                                    setlastDeleteMessage('deleteSignatories');
                                                                                                    setDeleteIndexSignatories(
                                                                                                        index
                                                                                                    );
                                                                                                    setDeleteMessage(
                                                                                                        "Are you sure you want to delete this signatory ?"
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                <i className="fa fa-trash"></i>
                                                                                            </span>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        );
                                                                    }
                                                                ))}</>
                                                                : ""}
                                                        </div>
                                                        <>
                                                            <button
                                                                className={`additionalbtn2 mtp20 ${(voidenvelopeId!=='' &&voidenvelopeId!==null && voidenvelopeId!==undefined)&& pmaDetails.status === "sent"? 'disabledBtn':''}`}
                                                                disabled={
                                                                    (voidenvelopeId!=='' &&voidenvelopeId!==null && voidenvelopeId!==undefined)&& pmaDetails.status === "sent"

                                           }
                                                                onClick={handleAddParties}
                                                            >                                                                + Add Signatories
                                                            </button>
                                                        </>
                                                                    
                                                        <div>
                                                            <input type="text" id="signfocusInput" onBlur={SaveData} onClick={SaveData} />
                                                            <p>
                                                                Acknowledged: This constitutes the complete
                                                                resolution of all claims herein
                                                            </p>
                                                            <p>
                                                                <span className="float-left">
                                                                    Hearing Officer: &nbsp;{" "}
                                                                </span>
                                                                <span className="float-left">
                                                                    {props.caseDetails["hearingOfficerName"]}
                                                                </span>
                                                                <input
                                                                    type="text"
                                                                    className="pmaEmail"
                                                                    style={{ width: "25rem" }}
                                                                    value={HearingOfficerEmailPMA}
                                                                    onChange={(e) =>
                                                                        setHearingOfficerEmail(e.target.value)
                                                                    }
                                                                    disabled={EDIT_NEUTRAL_EMAIL}
                                                                />
                                                            </p>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                            <hr />
                                            <div
                                                className="text-left"
                                                style={{ fontWeight: "600", marginTop: "20px" }}
                                            >
                                                Upload PMA and/or supporting documents
                                            </div>
                                            <p style={{ fontSize: "12px", textAlign: "left" }}>
                                                Please upload pdf/doc/docx formats only. Max 10MB file
                                                size allowed.
                                            </p>
                                        </>
                                        {/* New Code End */}

                                        <>
                                            <>
                                                <div className="row" style={{ margin: "0px" }}>
                                                    <div className="">
                                                        <div className="row">
                                                            {switchUploads === false &&
                                                                isuploaderrorMessage && (
                                                                    <div className="">
                                                                        {uploaderrorMessage && (
                                                                            <span className="action">
                                                                                <i
                                                                                    style={{ color: "red" }}
                                                                                    className="fa fa-warning"
                                                                                ></i>
                                                                            </span>
                                                                        )}
                                                                        <label
                                                                            style={{ color: "red", marginLeft: "5px" }}
                                                                            className="error-message error "
                                                                        >
                                                                            {uploaderrorMessage}
                                                                        </label>
                                                                    </div>
                                                                )}
                                                            {switchUploads === false &&
                                                                isuploaderrorMessage1 && (
                                                                    <div className="">
                                                                        <label
                                                                            style={{ color: "red", marginLeft: "5px" }}
                                                                            className="error-message error "
                                                                        >
                                                                            {uploaderrorMessage1}
                                                                        </label>
                                                                    </div>
                                                                )}
                                                        </div>

                                                        {/* <div style={{ marginBottom: '0.75rem', display: switchUploads ? "none" : "block" }} > */}
                                                        {switchUploads === false && (
                                                            <div  className={( decisionFileAvailable && !IsDocuSigned && pmaDetails.status === "sent") ? "disableditem": (isFormdisabled ? "disableditem": "")}>
                                                                {props.caseDetails["isPaid"] ? <></> : (!Utility.IsCaseDecision(props.caseDetails["caseStatus"]) && TimesheetNotSubmitted ? (
                                                                    <div className="row">
                                                                        <div style={{ display: "flex" }}>
                                                                            <span style={{ color: "red" }}>
                                                                                You must submit timesheet before
                                                                                proceeding with the PMA submission.
                                                                            </span>
                                                                        </div>

                                                                    </div>
                                                                ) : <></>)}
                                                                <FileUploader disabled={( decisionFileAvailable && !IsDocuSigned && pmaDetails.status === "sent") ? true : isFormdisabled}
                                                                    name="file_uploader"
                                                                    onDropHandler={onDropHandler}
                                                                    componentType="DRAG_DROP_BROWSE"
                                                                    acceptFileTypes={[
                                                                        "application/pdf",
                                                                        "application/msword",
                                                                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                                                    ]}
                                                                    dropTitle="Drag and drop files here to"
                                                                    dropSubTitle="Choose File"
                                                                    AllowedFileTypesErrorMessage={
                                                                        "File type not allowed. Allowed file types are .pdf and .doc/.docx"
                                                                    }
                                                                    maxFileSize={10000000} //// 10mb
                                                                    onFileRemove={onFileRemove}
                                                                    maxFileSizeErrorMessage={
                                                                        "Max allowed file size is 10MB"
                                                                    }
                                                                    showProgressBar={{ show: true }}
                                                                    renderUploadedFilesItem={(file1, index) =>
                                                                        renderUploadedFilesItem(file1, index)
                                                                    }
                                                                    onRef={(ref) => (childRef.current = ref)}
                                                                    regex={/^[A-Za-z0-9@ ]+$/}
                                                                    dropbackgroundClass="customDrop"
                                                                    restrictMultipleFiles={false}
                                                                    layout="2column"
                                                                   
                                                                />
                                                            </div>
                                                        )}

                                                      

                                                        {!switchUploads && (
                                                            <div style={{ textAlign: "left" }}>
                                                                <button
                                                                    name="docSubmit"
                                                                    disabled={
                                                                        !isFileUpload ||
                                                                        TimesheetNotSubmitted || isFormdisabled ||decisionFileAvailable
                                                                    }
                                                                    className={
                                                                        isFileUpload &&
                                                                            !TimesheetNotSubmitted && !isFormdisabled && !decisionFileAvailable
                                                                            ? "pmaButton btn-primary"
                                                                            : "pmaButtonDisabled btn-primary"
                                                                    }
                                                                    onClick={docSubmitHandler}
                                                                >
                                                                    Upload
                                                                </button>
                                                            </div>
                                                        )}
                                                        <div
                                                            style={{
                                                                textAlign: "left",
                                                                borderTop: "0px !important",
                                                                marginTop: "1rem",
                                                            }}
                                                        >
                                                           
                                                            {
                                                                ((Utility.IsCaseClosed(props.caseDetails["caseStatus"]) === true && (decisionFileAvailable !== null ||
                                                                 decisionFileAvailable !== "" ||
                                                                 decisionFileAvailable !== 'undefined' || decisionFileAvailable===true) ) ||((newErrors && newErrors.length > 0) || (Object.keys(plainTiffDefentError).length > 0) && (btnHandle === false && caseUnsettled === false)) || ( decisionFileAvailable && !IsDocuSigned && pmaDetails.status === "sent"))?
                                                                 <button
                                                                name="submitEsign"
                                                                disabled={true}
                                                                onClick={submitHandler}
                                                                className={"pmaButtonDisabled btn-primary"}
                                                            >
                                                                Preview e-Signature Document
                                                                
                                                            </button>
                                                                 :
                                                          
                                                           <button
                                                                name="submitEsign"
                                                                disabled={isFormdisabled || Utility.IsCaseDecision(props.caseDetails["caseStatus"])? true :

                                                                    props.caseDetails["isPaid"] ? false :

                                                                        ((file && file?.length > 0) ? true :

                                                                            (!TimesheetNotSubmitted
                                                                                ? (isFormSubmit === true &&

                                                                                    isValid) ||
                                                                                    (caseUnsettled) ||
                                                                                    decisionFileAvailable
                                                                                    ? false
                                                                                    : true
                                                                                : true)

                                                                        )


                                                                }
                                                                onClick={submitHandler}
                                                                className={isFormdisabled ||Utility.IsCaseDecision(props.caseDetails["caseStatus"])? "pmaButtonDisabled 0 btn-primary" :
                                                                    props.caseDetails["isPaid"] ? "pmaButton btn-primary" :

                                                                        (file && file?.length > 0) ? "pmaButtonDisabled 1 btn-primary" :
                                                                            !TimesheetNotSubmitted
                                                                                ? (isFormSubmit === true && isValid) ||
                                                                                    caseUnsettled ||
                                                                                    decisionFileAvailable
                                                                                    ? "pmaButton btn-primary"
                                                                                    : "pmaButtonDisabled 2 btn-primary"
                                                                                : "pmaButtonDisabled 3 btn-primary"
                                                                }
                                                            >
                                                                Preview e-Signature Document
                                                            </button>
                                                              }
                                                            {console.log("tempNewErrors",isFormSubmit, isValid ,
                                                                                    caseUnsettled,
                                                                                    decisionFileAvailable)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        </>

                                        <br></br>
                                        <br></br>
                                    </>
                                )}
                        </div>
                    </>
                )

                    :

                    (
                        <div className="LoadingData">
                            <iframe
                                title=""
                                src={Loading}
                                width="100%"
                                allowtransparency="true"
                                frameBorder="0"
                                marginWidth="0"
                                marginHeight="0"
                                scrolling="no"
                            ></iframe>

                            <h4>Loading...</h4>
                        </div>
                    )

                }
                {isdisplayAlertError === true && (
                    <AlertDialog
                        isdisplayAlert={false}
                        isdisplayAlertError={isdisplayAlertError}
                        updatedisplayAlertError={updatedisplayAlert}
                    ></AlertDialog>
                )}
                {openConfirmDialog && (
                    <AlertDialog
                        isdisplayAlert={false}
                        isdisplayAlertError={false}
                      
                        openConfirmDialog={openConfirmDialog}
                        closeConfirmDialog={closeConfirmDialog}
                        deleteMessage={deleteMessage}
                    ></AlertDialog>
                )}

                <div style={{ display: "none" }}>
                    <PMATemplate
                        caseDetails={props.caseDetails}
                        pmaDetails={pmaDetails}
                        caseUnsettled={caseUnsettled}
                        hearingCode={hearingCode}
                        hearingOfficerEmail={HearingOfficerEmailPMA}
                        totalSum={totalSum}
                    />
                </div>
            </LoadingOverlay>
        </div>
    );
}
